import { useERC20Contract } from '@/common/contracts/hooks/useERC20Contract';
import { useWallet } from '@/common/hooks/wagmi/useWallet';
import ERC20ApproveButton from '@/components/App/ERC20ApproveButton';
import { EthersValue } from '@/components/App/EthersValue';
import { useRequest } from 'ahooks';
import { message, ConfigProvider, Button, Input, List } from 'antd';
import { BigNumber } from 'ethers';
// import { t } from 'i18next';
import { useTranslation } from 'react-i18next';
import { useMemo, useState } from 'react';
import { AppModal, Field } from './Common';
import { useContract } from './useContract';
import { ConnectButton } from '@/components/App/ConnectButton';
import { getStakeInfo } from '@/service/farm';
import { ModalHeader } from './Farm';
import { formatUnits, parseUnits } from 'ethers/lib/utils';

function LpClaimModal({
  open,
  onClose,
  onSuccess,
  claimable,
}: {
  open: boolean;
  onClose: () => void;
  onSuccess: () => void;
  claimable?: BigNumber;
}) {
  const { provider } = useWallet();

  const { claim } = useContract();

  const { t } = useTranslation();

  const { loading, run } = useRequest(
    async () => {
      return claim();
    },
    {
      manual: true,
      ready: !!provider,
      onSuccess() {
        onSuccess?.();
        message.success(t('Claim success', 'Claim success'));
      },
      onError() {
        message.error(t('Claim failed', 'Claim failed'));
      },
    },
  );

  return (
    <AppModal
      title=<ModalHeader title={t('Claim', 'Claim')} />
      open={open}
      onCancel={onClose}
      footer={
        <ConfigProvider
          theme={{
            components: {
              Button: {
                colorBgContainerDisabled: '#626262',
                borderColorDisabled: '#626262',
              },
            },
            token: {
              colorPrimary: '#CF0057',
              controlHeight: 50,
            },
          }}
        >
          <Button
            type="primary"
            block
            loading={loading}
            onClick={() => {
              run();
            }}
          >
            {t('Confirm', 'Confirm')}
          </Button>
        </ConfigProvider>
      }
    >
      <div className="my-[10px] ">
        {t('You’ll claim all', 'You’ll claim all')}
        <span className="text-[#8069E3] mx-[5px] text-[18px]">
          <EthersValue value={claimable} placeholder="0" balance></EthersValue>
        </span>
        $eCAT.
      </div>
    </AppModal>
  );
}

function LPStakeModal({ open, onClose, onSuccess }: { open: boolean; onClose: () => void; onSuccess: () => void }) {
  const [value, setValue] = useState('');

  const { provider } = useWallet();

  const { stake } = useContract();

  const { balanceOf } = useERC20Contract();

  const { t } = useTranslation();

  const { data: LPBalance } = useRequest(
    async () => {
      return balanceOf(process.env.REACT_APP_LP_CONTRACT_ADDRESS!);
    },
    {
      ready: !!provider,
      refreshDeps: [open],
    },
  );

  const { loading, run } = useRequest(
    async () => {
      return stake(value);
    },
    {
      manual: true,
      ready: !!provider,
      onSuccess() {
        onSuccess?.();
        message.success(t('Stake success', 'Stake success'));
      },
      onError(err) {
        console.log('stake error', err);
        message.error(t('Stake failed', 'Stake failed'));
      },
    },
  );

  function handleSummit() {
    if (parseUnits(value).gt(LPBalance!)) {
      message.error(t('Insufficient balance', 'Insufficient balance'));

      return;
    }

    run();
  }

  return (
    <AppModal
      title=<ModalHeader title={t('Stake', 'Stake')} />
      open={open}
      onCancel={onClose}
      footer={
        <ConfigProvider
          theme={{
            components: {
              Button: {
                colorBgContainerDisabled: '#626262',
                borderColorDisabled: '#626262',
              },
            },
            token: {
              colorPrimary: '#0AAE55',

              controlHeight: 50,
            },
          }}
        >
          <ERC20ApproveButton
            tokenAddress={process.env.REACT_APP_LP_CONTRACT_ADDRESS}
            spenderAddress={process.env.REACT_APP_LP_STAKE_CONTRACT_ADDRESS}
            allowance={`${value}`}
          >
            <Button
              type="primary"
              block
              loading={loading}
              onClick={() => {
                handleSummit();
              }}
            >
              {t('Stake', 'Stake')}
            </Button>
          </ERC20ApproveButton>
          <div className="justify-between items-center flex">
            <div className="flex justify-start items-center gap-x-[5px]">
              <div className="my-[10px] text-opacity-75">{t('Balance', 'Balance')}:</div>
              <div>
                <EthersValue value={LPBalance} balance></EthersValue>
              </div>
            </div>
          </div>
        </ConfigProvider>
      }
    >
      {/*<div className="my-[10px] text-opacity-75">{t('Amount', 'Amount')}</div>*/}
      <ConfigProvider
        theme={{
          components: {
            Input: {
              colorBgContainer: '#1B153A',
              colorBorder: '#1B153A',
              colorText: '#fff',
            },
          },
          token: {
            colorPrimary: '#7F68E2',
            controlHeight: 50,
          },
        }}
      >
        <Input
          value={value}
          disabled={loading}
          placeholder={t('Enter the amount', 'Enter the amount')}
          onChange={(e) => {
            setValue(e.target.value);
          }}
          onBlur={(e) => {
            setValue(e.target.value.replace(/[^\d.]/g, ''));
          }}
        ></Input>
      </ConfigProvider>
    </AppModal>
  );
}
// suffix={<Button type="link">All</Button>}

function LPUnStakeStakeModal({
  open,
  onClose,
  onSuccess,
  staked,
}: {
  open: boolean;
  onClose: () => void;
  onSuccess: () => void;
  staked?: BigNumber;
}) {
  const [value, setValue] = useState('');

  const { provider } = useWallet();

  const { unStakeLp } = useContract();

  const { t } = useTranslation();

  const { loading, run } = useRequest(
    async () => {
      return unStakeLp(value);
    },
    {
      manual: true,
      ready: !!provider,
      onSuccess() {
        onSuccess?.();
        message.success(t('Unstake success', 'Unstake success'));
      },
      onError(err) {
        console.log('stake error', err);
        message.error(t('Unstake failed', 'Unstake failed'));
      },
    },
  );

  return (
    <AppModal
      title=<ModalHeader title={t('Unstake', 'Unstake')} />
      open={open}
      onCancel={onClose}
      footer={
        <ConfigProvider
          theme={{
            components: {
              Button: {
                colorBgContainerDisabled: '#626262',
                borderColorDisabled: '#626262',
              },
            },
            token: {
              colorPrimary: '#CF0057',
              controlHeight: 50,
            },
          }}
        >
          <ERC20ApproveButton
            tokenAddress={process.env.REACT_APP_LP_CONTRACT_ADDRESS}
            spenderAddress={process.env.REACT_APP_LP_STAKE_CONTRACT_ADDRESS}
            allowance={`${value}`}
          >
            <Button
              type="primary"
              block
              loading={loading}
              onClick={() => {
                run();
              }}
            >
              {t('UnStake', 'UnStake')}
            </Button>
          </ERC20ApproveButton>
          <div className="justify-between items-center flex">
            {/* <div className="flex justify-start items-center gap-x-[5px]">
              <div className="my-[10px] text-opacity-75">{t('APY', 'APY')}:</div>
              <div>-</div>
            </div> */}

            <div className="flex justify-start items-center gap-x-[5px]">
              <div className="my-[10px] text-opacity-75">{t('Balance', 'Balance')}:</div>
              <div>
                <EthersValue value={staked}></EthersValue>
              </div>
            </div>
          </div>
        </ConfigProvider>
      }
    >
      {/*<div className="my-[10px] text-opacity-75">{t('Amount', 'Amount')}</div>*/}
      <ConfigProvider
        theme={{
          components: {
            Input: {
              colorBgContainer: '#1B153A',
              colorBorder: '#1B153A',
              colorText: '#fff',
            },
          },
          token: {
            colorPrimary: '#7F68E2',
            controlHeight: 50,
          },
        }}
      >
        <Input
          value={value}
          disabled={loading}
          placeholder={t('Enter the amount', 'Enter the amount')}
          onChange={(e) => {
            setValue(e.target.value);
          }}
          onBlur={(e) => {
            setValue(e.target.value.replace(/[^\d.]/g, ''));
          }}
        ></Input>
      </ConfigProvider>
    </AppModal>
  );
}

// suffix={<Button type="link">All</Button>}

export function Lp() {
  const { provider, address } = useWallet();

  const { getStakeInfo, getPoolInfo } = useContract();

  const [stakeOpen, setStakeOpen] = useState(false);

  const [unStakeOpen, setUnStakeOpen] = useState(false);

  const [claimOpen, setClaimOpen] = useState(false);

  const { t } = useTranslation();

  const { data, refreshAsync } = useRequest(
    () => {
      return getStakeInfo(address!);
    },
    { ready: !!provider && !!address },
  );

  const { data: poolInfo } = useRequest(
    () => {
      return getPoolInfo();
    },
    { ready: !!provider && !!address },
  );

  const percentage = useMemo(() => {
    try {
      return ((+formatUnits(data!.staked!) / +formatUnits(poolInfo!.totalStaked)) * 100).toFixed(2);
    } catch (err) {
      return 0;
    }
  }, [data, poolInfo]);

  const earned = useMemo(() => {
    try {
      return data!.earnings!.add(data!.received);
    } catch (err) {
      return BigNumber.from(0);
    }
  }, [data]);

  return (
    <div>
      <div className="mt-[30px] mb-[40px] text-[#777E90] text-center">
        {t('Stake eCAT-USDT LP to earn $eCAT', 'Stake eCAT-USDT LP to earn $eCAT')}
      </div>
      <div className="border-[#241C4C] border border-solid p-[20px] rounded md:p-[10px]">
        <div className="flex justify-between items-center md:flex-wrap md:justify-start ">
          <img src="/static/images/icon/eCat-U.png" className="w-[48px] md:hidden" />
          <Field
            className="md:w-1/2"
            title={t('Staked(eCAT-USDT LP)', 'Staked(eCAT-USDT LP)')}
            value={<EthersValue value={data?.staked}></EthersValue>}
          ></Field>
          <Field
            className="md:w-1/2"
            title={t('Total Earned(eCAT)', 'Total Earned(eCAT)')}
            value={<EthersValue value={earned}></EthersValue>}
          ></Field>
          <Field
            className="md:w-1/2 md:mt-[10px]"
            title={t('Claimable(eCAT)', 'Claimable(eCAT)')}
            value={<EthersValue value={data?.earnings} balance></EthersValue>}
          ></Field>
          <Field className="md:w-1/2 md:mt-[10px]" title="%" value={percentage}></Field>
        </div>

        <div className="flex justify-between items-center mt-[20px] gap-x-[15px]">
          <ConnectButton>
            <ConfigProvider
              theme={{
                components: {
                  Button: {
                    colorBgContainerDisabled: '#626262',
                    borderColorDisabled: '#626262',
                  },
                },
                token: {
                  colorPrimary: '#0AAE55',
                  controlHeight: 50,
                },
              }}
            >
              <Button
                type="primary"
                block
                onClick={() => {
                  setStakeOpen(true);
                }}
              >
                {t('Stake', 'Stake')}
              </Button>
            </ConfigProvider>

            <ConfigProvider
              theme={{
                components: {
                  Button: {
                    colorBgContainerDisabled: '#626262',
                    borderColorDisabled: '#626262',
                  },
                },
                token: {
                  colorPrimary: '#CF0057',
                  controlHeight: 50,
                },
              }}
            >
              <Button
                type="primary"
                block
                onClick={() => {
                  setUnStakeOpen(true);
                }}
              >
                {t('Unstake', 'Unstake')}
              </Button>
            </ConfigProvider>
            <ConfigProvider
              theme={{
                components: {
                  Button: {
                    colorBgContainerDisabled: '#626262',
                    borderColorDisabled: '#626262',
                  },
                },
                token: {
                  colorPrimary: '#7F68E2',
                  controlHeight: 50,
                },
              }}
            >
              <Button
                type="primary"
                block
                disabled={data?.earnings?.eq(0)}
                onClick={() => {
                  setClaimOpen(true);
                }}
              >
                {t('Claim', 'Claim')}
              </Button>
            </ConfigProvider>
          </ConnectButton>
        </div>
      </div>

      <div className="py-[20px] flex justify-center items-center">
        <ConfigProvider
          theme={{
            token: {
              colorPrimary: '#FFB41D',
              colorLink: '#FFB41D',
            },
          }}
        >
          <Button
            target="_blank"
            type="link"
            href="https://pancakeswap.finance/v2/add/0x400484fDE15546F30569D6Fecc61B09B9eF04950/0x55d398326f99059fF775485246999027B3197955"
            className="text-[24px]"
          >
            {t('Get eCAT-USDT LP', 'Get eCAT-USDT LP')}
          </Button>
        </ConfigProvider>
      </div>

      <LPStakeModal
        open={stakeOpen}
        onClose={() => {
          setStakeOpen(false);
        }}
        onSuccess={() => {
          setStakeOpen(false);
          refreshAsync();
        }}
      />

      <LPUnStakeStakeModal
        open={unStakeOpen}
        staked={data?.staked}
        onClose={() => {
          setUnStakeOpen(false);
        }}
        onSuccess={() => {
          setUnStakeOpen(false);
          refreshAsync();
        }}
      />

      <LpClaimModal
        open={claimOpen}
        claimable={data?.earnings}
        onClose={() => {
          setClaimOpen(false);
        }}
        onSuccess={() => {
          setClaimOpen(false);
          refreshAsync();
        }}
      />
    </div>
  );
}
