import { Account, WalletKey } from '@/common/types/App';
import { ConfigProvider, Modal, message } from 'antd';
import { useWalletStore } from '@/stores/useBitcoinWalletStore';
import { useWallet } from '@/common/hooks/wagmi/useWallet';
import { Connector } from 'wagmi';
import { Preference } from '@/common/preferences';
import { useTranslation } from 'react-i18next';

const iconMap: Record<string, string> = {
  ['OKX Wallet']: '/static/images/wallet/okx.png',
  BinanceWallet: '/static/images//wallet/binance.png',
  MetaMask: '/static/images//wallet/metamask.png',
  Bitkeep: '/static/images//wallet/bitkeep.png',
  TrustWallet: '/static/images//wallet/trustwallet.png',
  GateWallet: '/static/images//wallet/gate.png',
};

export function Profile({ onSuccess }: { onSuccess?: (address: string) => void }) {
  const { connectors, connect, currentConnector } = useWallet({
    onSuccess(data) {
      console.log('currentConnector----success', currentConnector);
      if (data?.connectorName) {
        Preference.setCurrentWagmiWallet(data?.connectorName || '');
      }
      onSuccess?.(data?.address ?? '');
    },
    onError(err) {
      message.error(err.message);
      console.log(err.message);
    },
  });

  function handleConnect(item: Connector<any, any>) {
    connect({ connector: item });
  }

  return (
    <div className="flex justify-center items-center flex-col  gap-y-2 text-lg cursor-pointer gap-x-[2%]">
      {connectors.map((item) => {
        return (
          <div
            onClick={() => {
              handleConnect(item);
            }}
            className="w-full flex justify-start items-center gap-x-3 text-white text-opacity-70 bg-[#1e2128] px-[15px] py-[15px] rounded-md  text-[14px]"
            key={item.name}
          >
            <img className="w-[22px] rounded-sm" src={iconMap[item.name]} />

            {item.name}
          </div>
        );
      })}
    </div>
  );
}

export function Bep20WalletModal({
  open,
  onClose,
  onConnected,
}: {
  open: boolean;
  onChoose?: (key: WalletKey) => void;
  onClose?: () => void;
  onConnected?: (address: string) => void;
}) {
  const { t } = useTranslation();

  return (
    <ConfigProvider
      theme={{
        components: {
          Modal: {
            colorBgContainer: '#181a1f',
            colorBgLayout: '#181a1f',
            colorBgBase: '#181a1f',
            contentBg: '#181a1f',
          },
        },
        token: {},
      }}
    >
      <Modal
        open={open}
        onCancel={() => {
          onClose?.();
        }}
        footer={false}
        width={460}
        title={t('Connect wallet', 'Connect wallet')}
      >
        <div className="rounded-[35px] pt-[15px]">
          <Profile onSuccess={onConnected} />
        </div>
      </Modal>
    </ConfigProvider>
  );
}
