import { CatStake__factory } from '@/common/contracts/typechain';
import { useWallet } from '@/common/hooks/wagmi/useWallet';
import { parseUnits } from 'ethers/lib/utils';

export function useStake() {
  const { provider } = useWallet();

  function getContract() {
    return CatStake__factory.connect(process.env.REACT_APP_CAT_STAKE_CONTRACT_ADDRESS!, provider);
  }

  async function stake(amount: string) {
    const tx = await getContract().stake(parseUnits(amount));

    const res = await tx.wait();

    return res;
  }

  async function unStake(amount: string) {
    const tx = await getContract().unstake(parseUnits(amount));

    const res = await tx.wait();

    return res;
  }

  async function getTotalStaked() {
    const res = await getContract().totalStaked();

    return res;
  }

  async function getDiscountConfig() {
    const res = await getContract().discountConfig();

    return res;
  }

  async function getDiscount(address: string) {
    const res = await getContract().getDiscount(address);

    return res;
  }

  async function getCostSavings(address: string) {
    const res = await getContract().users(address);

    return res;
  }

  return {
    stake,
    unStake,
    getTotalStaked,
    getDiscountConfig,
    getCostSavings,
    getDiscount,
  };
}
