import Storage from '@/common/utils/Storage';

const storage = new Storage({ engine: 'sessionStorage', prefix: 'copycat' });

const get = (key: string) => {
  return storage.get(key);
};

const set = (key: string, data: any) => {
  storage.set(key, data);
};

const del = (key: string) => {
  storage.delete(key);
};

export default { get, set, del };
