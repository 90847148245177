import request from '@/common/request/whitelist';

export const prepareAuth = async (payload: { walletAddress: string }) => {
  return request.post<{ messageToSign: string }>('/v1/accounts/prepareAuth', payload);
};

export const getWhitelistStatus = async () => {
  return request.get<{
    accountI: number;
    createdAt: string;
    id: number;
    submitted: string;
    updatedAt: string;
    bicaBalance: string;
  }>('/v1/accounts/submits/self', null);
};

export const submitAddress = async (payload: { content: string }) => {
  return request.post<any>('/v1/accounts/submits', payload);
};

export const getHasRegistered = async (payload: { walletAddress: string }) => {
  return request.get<{ result: boolean }>('/v1/accounts/hasRegistered', payload);
};
