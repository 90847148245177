import { shortenAddress } from '@/common/utils';
import { useEffect, useMemo } from 'react';
import { useAccount, useConnect, useDisconnect, useWalletClient, usePublicClient, WalletClient } from 'wagmi';
import { providers } from 'ethers';

export function walletClientToSigner(walletClient: WalletClient) {
  const { account, chain, transport } = walletClient;

  const network = {
    chainId: chain.id,
    name: chain.name,
    ensAddress: chain.contracts?.ensRegistry?.address,
  };

  const provider = new providers.Web3Provider(transport, network);

  const signer = provider.getSigner(account.address);

  return signer;
}

export function useWallet({
  onError,
  onMutate,
  onSettled,
  onSuccess,
  onAccountChange,
  onChainChange,
  onDisconnect,
}: {
  onError?: (err: any) => void;
  onMutate?: () => void;
  onSettled?: () => void;
  onSuccess?: (data?: { address: string; connectorName: string }) => void;
  onAccountChange?: (address: string) => void;
  onChainChange?: (chainId: number) => void;
  onDisconnect?: () => void;
} = {}) {
  const { connect, reset, connectors, error, isLoading, pendingConnector } = useConnect({
    onError,
    onMutate,
    onSettled,
    onSuccess(data) {
      onSuccess?.({ address: data.account, connectorName: data.connector!.name });
    },
  });

  const { address, isConnected, connector } = useAccount({
    onDisconnect() {
      onDisconnect?.();
    },
  });

  const { disconnect } = useDisconnect();

  const { data: walletClient } = useWalletClient({
    onSuccess() {
      onSuccess?.();
    },
  });

  const publicClient = usePublicClient();

  const displayAddress = useMemo(() => {
    return shortenAddress(address);
  }, [address]);

  const defaultConnector = useMemo(() => {
    return connectors?.[0];
  }, [connectors]);

  const ethersProvider = useMemo(
    () => (walletClient ? walletClientToSigner(walletClient!) : undefined),
    [walletClient],
  )!;

  useEffect(() => {
    const handleConnectorUpdate = ({ account, chain }: any) => {
      if (account) {
        onAccountChange?.(account);
      } else if (chain) {
        onChainChange?.(chain.id);
      }
    };

    if (connector) {
      connector?.on('change', handleConnectorUpdate);
    }

    return () => {
      connector?.off('change', handleConnectorUpdate);
    };

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [connector]);

  return {
    address,
    walletClient,
    publicClient,
    provider: ethersProvider,
    connectors,
    currentConnector: connector,
    error,
    isLoading,
    pendingConnector,
    isConnected,
    displayAddress,
    defaultConnector,
    connect,
    disconnect,
    reset,
  };
}
