import HttpRequest from '@/common/utils/Axios';
import { errorHandler } from './errorHandler';
import { Preference } from '../preferences';

const api = new HttpRequest({ baseURL: process.env.REACT_APP_VAULT_API });

api.instance.interceptors.request.use(
  (config) => {
    config.headers.Authorization = Preference.getToken();

    return config;
  },
  (err) => Promise.reject(err),
);

api.instance.interceptors.response.use(
  (res) => {
    console.log(res.config.url, '------>', res.data);

    return res.data;
  },
  (err) => errorHandler(err),
);

export default api;
