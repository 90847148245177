import { Preference } from '@/common/preferences';
import { Account, WalletKey } from '@/common/types/App';
import { create } from 'zustand';
// import { ethers } from 'ethers';
// import { parseBytes32String } from 'ethers/lib/utils';

interface WalletStateState {
  currentWallet: WalletKey | null;

  account: Account | null;

  setCurrentWallet(key: WalletKey): void;

  setAccount(account: Account): void;

  clearAccount(): void;
}

export const useWalletStore = create<WalletStateState>((set) => ({
  currentWallet: null as WalletKey | null,

  account: Preference.getAccount() as Account | null,

  setCurrentWallet(key: WalletKey) {
    Preference.setCurrentWallet(key);

    set({
      currentWallet: key,
    });
  },

  setAccount(account: Account) {
    Preference.setAccount(account);

    set({
      account,
    });
  },

  clearAccount() {
    Preference.clearAccount();

    set({
      account: null,
    });
  },
}));
