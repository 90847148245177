import { useChain } from '@/common/hooks/wagmi/useChain';
import { Dropdown, MenuProps } from 'antd';
import classNames from 'classnames';
import { useMemo } from 'react';

function BNBItem({ inHeader }: { inHeader?: boolean }) {
  return (
    <div className="flex justify-start items-center gap-x-[5px] py-[5px]">
      <img className="w-[24px]" src="/static/images/icon/BNB.webp" />{' '}
      <span className={classNames({ 'md:hidden': inHeader })}>BNB Chain</span>
    </div>
  );
}

function OPBNBItem({ inHeader }: { inHeader?: boolean }) {
  return (
    <div className="flex justify-start items-center gap-x-[5px]  py-[5px]">
      <img className="w-[24px]" src="/static/images/icon/BNB.webp" />
      <span className={classNames({ 'md:hidden': inHeader })}>opBNB(coming soon)</span>
    </div>
  );
}

function ErrorNetwork() {
  return (
    <div className="flex justify-center items-center gap-x-[5px]">
      <i className="bufficon-v2 bi2-alert-line wrong-icon text-[#ffa723] text-[18px]"></i>
      <span className="md:hidden text-white">Wrong Network</span>
    </div>
  );
}

export function ChainDropdown() {
  const { switchNetwork, chain } = useChain();

  const isCorrect = useMemo(() => {
    return chain?.id === 56;
  }, [chain?.id]);

  const items: MenuProps['items'] = [
    {
      key: '1',
      label: (
        <div
          onClick={() => {
            switchNetwork?.(56);
          }}
        >
          <BNBItem />
        </div>
      ),
    },
    {
      key: '2',
      label: <OPBNBItem />,
      disabled: true,
    },
  ];

  return (
    <Dropdown menu={{ items }}>
      <div className="border-[#241C4C] border border-solid px-[8px] py-[4px] text-[14px] rounded cursor-pointer text-white min-h-[44px] flex justify-center items-center">
        {isCorrect ? <BNBItem inHeader /> : <ErrorNetwork />}
      </div>
    </Dropdown>
  );
}
