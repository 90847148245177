import { useMemo } from 'react';
import { useOkxWallet } from './useOkxWallet';
import { useUniSat } from './useUniSat';
import { createContext } from 'react';
import { Account, WalletKey } from '@/common/types/App';
import { useWalletStore } from '@/stores/useBitcoinWalletStore';

export type WalletPayload = {
  onSuccess?(): void;
  onAccountChange?(): void;
  onDisconnect?(): void;
  onConnect?(account: Account): void;
  onConnectError?(): void;
};

export const WalletContext = createContext<{ account?: Account }>({});

export function useWallet(payload?: WalletPayload) {
  const { currentWallet, account } = useWalletStore();

  const unisat = useUniSat(payload);

  const okx = useOkxWallet(payload);

  const wallet = useMemo(() => {
    return currentWallet === 'okxwallet' ? okx : unisat;
  }, [currentWallet, okx, unisat]);

  const isConnected = useMemo(() => {
    return !!account?.address;
  }, [account]);

  function getWallet(key: WalletKey) {
    return key === 'okxwallet' ? okx : unisat;
  }

  return {
    ...wallet,
    account: account,
    isConnected,
    address: account?.address,
    getWallet,
  };
}
