import { ConnectButton } from '@/components/App/ConnectButton';
import { UserInfo } from '@/components/App/UserInfo';
import { CaretRightOutlined, CloseOutlined } from '@ant-design/icons';
import { Collapse, ConfigProvider, Drawer, Dropdown } from 'antd';
import classNames from 'classnames';
import React, { useEffect, useMemo, useState } from 'react';
import { NavLink, useLocation } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import { ChainDropdown } from '@/components/App/ChainDropdown';

function padHost(path: string) {
  return `${process.env.REACT_APP_ORIGIN_WEB_URL}${path}`;
}

const langMap: any = {
  en: 'English',
  zh: '繁體中文',
};

const LangButton = ({ isMobile }: { isMobile?: boolean }) => {
  const { i18n } = useTranslation();

  const [currentLang, setCurrentLang] = useState<any>('en');

  useEffect(() => {
    const lang = i18n.resolvedLanguage || 'en';

    setCurrentLang(langs.find((item) => item.key === lang)?.key);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [i18n.resolvedLanguage]);

  const onClick = (e: any) => {
    console.log('click ', e);
    i18n.changeLanguage(e);
    console.log(i18n.resolvedLanguage);
  };

  const langs = [
    {
      key: 'en',
      label: (
        <div
          key={'en'}
          className={classNames(
            'text-white flex items-center',
            isMobile
              ? 'w-full justify-start h-[74px] text-left text-[15px] pl-[20px]'
              : 'w-[180px]  h-[56px] justify-center',
            isMobile && currentLang === 'en' ? 'bg-[#1E2128]' : '',
          )}
          onClick={() => {
            onClick('en');
            setOpen(false);
          }}
        >
          English
        </div>
      ),
    },
    {
      key: 'zh',
      label: (
        <div
          key={'zh'}
          className={classNames(
            'text-white flex  items-center',
            isMobile ? 'w-full h-[74px] text-left text-[15px] pl-[20px]' : 'w-[180px]  h-[74px] justify-center',
            isMobile && currentLang === 'zh' ? 'bg-[#1E2128]' : '',
          )}
          onClick={() => {
            onClick('zh');
            setOpen(false);
          }}
        >
          繁體中文
        </div>
      ),
    },
  ];

  const [open, setOpen] = useState(false);

  return isMobile ? (
    <>
      <div
        key={'lang'}
        className="text-white py-[24px] px-[17px] flex justify-between items-center w-full"
        onClick={(e) => {
          e.stopPropagation();
          setOpen(true);
        }}
      >
        {langMap[currentLang]}
        <CaretRightOutlined />
      </div>

      <Drawer
        title={<div className="py-[10px]">Switch Language</div>}
        className="app-drawer app-drawer-lang"
        getContainer={() => document.body}
        height={'auto'}
        key="LangDrawer"
        placement="bottom"
        onClose={() => {
          setOpen(false);
        }}
        open={open}
      >
        <div className="pb-[29px]">{langs.map((item) => item.label)}</div>
      </Drawer>
    </>
  ) : (
    <Dropdown menu={{ items: langs }} placement="bottom">
      <i className="bufficon-v2 bi2-global-fill icon-lang el-popover__reference text-white !text-[24px] cursor-pointer"></i>
    </Dropdown>
  );

  //

  // return <Menu onClick={onClick} selectedKeys={[currentLang]} mode="horizontal" items={langs} />;
};

function useMenu({ isMobile = false } = {}) {
  const { pathname } = useLocation();

  const { t } = useTranslation();

  const { i18n } = useTranslation();

  const items = [
    {
      key: '/earn/vault',
      label: (
        <NavLink to="/earn/vault" key="4">
          {t('Vault', 'Vault')}
        </NavLink>
      ),
    },
    {
      key: '/earn/catStaking',
      label: (
        <NavLink to="/earn/catStaking" key="1">
          CAT {t('Staking', 'Staking')}
        </NavLink>
      ),
    },
    {
      key: '/earn/cudStaking',
      label: <a href={padHost('/pnl-pool')}> CUD {t('Staking', 'Staking')}</a>,
    },
  ];

  const menus = useMemo(() => {
    const isActive = pathname.startsWith('/earn');

    const arr = [
      {
        label: t('Home', 'Home'),
        path: padHost('/'),
        isExternal: true,
      },
      {
        label: t('Trade', 'Trade'),
        path: padHost('/trade#BTC/USDT'),
        isExternal: true,
      },
      {
        label: t('Earn', 'Earn'),
        path: '/earn',
        isExternal: false,
        component: () =>
          isMobile ? (
            <>
              <Collapse
                onChange={(e) => {
                  console.log(e);
                }}
                className="w-full"
                bordered={false}
                expandIconPosition="end"
                expandIcon={({ isActive }) => <CaretRightOutlined rotate={isActive ? 90 : 0} />}
                items={[
                  {
                    key: 'Earn',
                    label: (
                      <div className="!text-white text-opacity-0 font-medium text-[15.25px] pl-[17px] ">
                        {t('Earn', 'Earn')}
                      </div>
                    ),
                    // showArrow: false,
                    children: items?.map((item) => {
                      return (
                        <div key={item.key} className="py-[17px] text-white px-[17px]">
                          {item.label}
                        </div>
                      );
                    }),
                    // extra: <CaretDownOutlined className="mr-[17px]" />,
                  },
                ]}
              />
            </>
          ) : (
            <ConfigProvider
              theme={{
                components: {
                  Dropdown: {
                    colorBgElevated: '#1B153A',
                  },
                },
              }}
            >
              <Dropdown menu={{ items }} placement="bottom">
                <a
                  className={classNames(
                    'h-[40px] mr-[40px] cursor-pointer flex justify-center items-center transition-all no-underline relative',
                    isActive ? 'text-[#fff] active:text-[#fff]' : 'text-opacity-70 text-[#FFFFFF]',
                  )}
                  onClick={(e) => e.preventDefault()}
                >
                  {t('Earn', 'Earn')}
                </a>
              </Dropdown>
            </ConfigProvider>
          ),
      },
      {
        label: t('IDO', 'IDO'),
        path: '/eCatIDO',
        isExternal: false,
      },
      // {
      //   label: t('IDO Whitelist', 'IDO Whitelist'),
      //   path: '/whitelist',
      //   isExternal: false,
      // },
      {
        path: '/tradeToEarn',
        label: t('Trade to earn', 'Trade to earn'),
        isExternal: false,
      },
      {
        path: '/eCat',
        label: 'eCAT To CAT',
        isExternal: false,
      },

      {
        path: '/farm',
        label: t('Farm', 'Farm'),
        isExternal: false,
      },

      {
        label: t('Docs', 'Docs'),
        path: 'https://copycat-finance.gitbook.io/copycat-finance-v1-1.0/overview/welcome-to-copycat-dex',
        isExternal: true,
      },
      // {
      //   label: 'BitcatDex(BRC20)',
      //   path: 'https://bitcat.copycat.finance/',
      //   isExternal: true,
      // },
    ];

    if (isMobile) {
      arr.push({
        label: 'lan',
        path: '/lan',
        component: () => <LangButton key={'LangButton'} isMobile />,
      } as any);
    }

    return arr;
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [pathname, i18n.resolvedLanguage]);

  return { menus };
}

function DrawerMenu() {
  const [open, setOpen] = useState(false);

  const onClose = () => {
    setOpen(false);
  };

  const { menus } = useMenu({ isMobile: true });

  const { pathname } = useLocation();

  const isWhitelist = useMemo(() => {
    return pathname.startsWith('/whitelist');
  }, [pathname]);

  return (
    <>
      {/* <MenuOutlined
        className="text-[24px] text-white cursor-pointer"
        onClick={() => {
          setOpen(true);
        }}
      /> */}

      {!isWhitelist && (
        <i
          className="bufficon-v2 bi2-bar-chart-horizontal-line menu-icon text-white !text-[24px]  cursor-pointer"
          onClick={() => {
            setOpen(true);
          }}
        ></i>
      )}

      <ConfigProvider
        theme={{
          components: {
            Collapse: {
              contentBg: '#0F1216',
              headerBg: 'transparent',
            },
            Drawer: {
              colorBgContainer: 'rgba(0,0,0,.7)',
              colorBgBase: 'rgba(0,0,0,.7)',
              colorBgElevated: '#181A1F',
            },
          },
        }}
      >
        <Drawer
          push={false}
          key="MenuDrawer"
          placement="left"
          width={'100%'}
          onClose={onClose}
          closable={false}
          open={open}
          className="app-drawer"
        >
          <div className="relative w-full h-full">
            <div className="flex justify-between items-center px-[10px] py-[20px]">
              <div className="flex justify-start items-start">
                <NavLink to="https://www.copycatdex.io/home">
                  <img className="w-[187px]  md:w-[106px] md:mr-[10px]" src="/static/images/logo.png" alt="logo" />
                </NavLink>
                {/* <div className="px-[10px] bg-[#f7931a] text-white rounded-lg text-[12px]">Beta</div> */}
              </div>

              <CloseOutlined
                className="text-white"
                onClick={() => {
                  setOpen(false);
                }}
              />
            </div>
            {/* 
            <div className="absolute left-0 bottom-0">
              <ConnectButton className="relative top-[-2px]">
                <UserInfo></UserInfo>
              </ConnectButton>
            </div> */}

            <div className={'flex justify-start items-center flex-col'}>
              {menus.map((item) => {
                if (item.component)
                  return (
                    <div className="flex justify-start w-full" key={item.label}>
                      <item.component key={item.path} />
                    </div>
                  );

                return (
                  <div className="relative w-full px-[17px] py-[14px]" key={item.label}>
                    <NavLink
                      className={({ isActive }) => {
                        return classNames(
                          'h-[40px] mr-[40px] text-left cursor-pointer flex justify-start items-center transition-all no-underline relative text-[15.25px]',
                          isActive ? 'text-[#fff] active:text-[#fff] ' : 'text-[#FFFFFF]',
                        );
                      }}
                      to={item.path}
                      onClick={(e) => {
                        if (item.isExternal) {
                          e.stopPropagation();
                          window.location.href = item.path;
                        }
                      }}
                    >
                      <>{item.label}</>
                    </NavLink>
                  </div>
                );
              })}
            </div>
          </div>
        </Drawer>
      </ConfigProvider>
    </>
  );
}

function Header() {
  const { menus } = useMenu();

  const { pathname } = useLocation();

  const isWhitelist = useMemo(() => {
    return pathname.startsWith('/whitelist');
  }, [pathname]);

  return (
    <div>
      <div className="min-w-[100vw]">
        <div className="w-full flex justify-between items-center py-[22px] px-[40px] md:px-[15px] md:py-[15px]">
          <div className="flex justify-start items-center">
            <div className="hidden md:block mr-[10px]">
              <DrawerMenu></DrawerMenu>
            </div>
            <NavLink to="https://www.copycatdex.io/home">
              <img
                className="w-[187px]  md:w-[106px] md:mr-[20px] relative  md:top-[3px]"
                src="/static/images/logo.png"
                alt="logo"
              />
            </NavLink>

            <div className={'flex justify-start items-center md:hidden ml-[40px] text-[14px]'}>
              {menus.map((item) => {
                if (item.component) return <item.component key={item.path} />;

                return (
                  <div className="relative" key={item.label}>
                    <NavLink
                      className={({ isActive }) => {
                        return classNames(
                          'h-[40px] mr-[40px] cursor-pointer flex justify-center items-center transition-all no-underline relative',
                          isActive ? 'text-[#fff] active:text-[#fff] ' : 'text-opacity-70 text-[#FFFFFF]',
                        );
                      }}
                      to={item.path}
                      onClick={(e) => {
                        if (item.isExternal) {
                          e.stopPropagation();
                          window.location.href = item.path;
                        }
                      }}
                    >
                      <>{item.label}</>
                    </NavLink>
                  </div>
                );
              })}
            </div>
          </div>

          <div className="flex justify-end items-center gap-x-[20px] md:min-w-[auto] min-w-[100px] md:gap-x-[10px]">
            {!isWhitelist && (
              <>
                <div className="md:hidden">
                  <LangButton />
                </div>

                <ConnectButton className="relative top-[-2px]">
                  <>
                    <div>
                      <ChainDropdown />
                    </div>
                    <UserInfo></UserInfo>
                  </>
                </ConnectButton>
              </>
            )}
          </div>
        </div>
      </div>
    </div>
  );
}

export default Header;
