import { Finance__factory } from '@/common/contracts/typechain';
import { useWallet } from '@/common/hooks/wagmi/useWallet';

export function useFinance() {
  const { provider } = useWallet();

  function getContract() {
    return Finance__factory.connect(process.env.REACT_APP_FINANCE_CONTRACT_ADDRESS!, provider);
  }

  async function claim({
    erc20,
    maxAmount,
    deadline,
    signature,
  }: {
    erc20: string;
    maxAmount: string;
    deadline: string;
    signature: string;
  }) {
    const tx = await getContract().claim(erc20, maxAmount, deadline, signature);

    const res = await tx.wait();

    return res;
  }

  function getClaimed(address: string) {
    return getContract().claimed(process.env.REACT_APP_ECAT_CONTRACT_ADDRESS!, address);
  }

  return {
    claim,
    getClaimed,
  };
}
