import Header from '@/components/UI/Header';
import { Button, ConfigProvider, Input, Tabs, message } from 'antd';
import type { TabsProps } from 'antd';
import { useStake } from './useStake';
import { useWallet } from '@/common/hooks/wagmi/useWallet';
import { useRequest } from 'ahooks';
import { EthersValue } from '@/components/App/EthersValue';
import { useState } from 'react';
import { useERC20Contract } from '@/common/contracts/hooks/useERC20Contract';
import ERC20ApproveButton from '@/components/App/ERC20ApproveButton';
import { ConnectButton } from '@/components/App/ConnectButton';
import { parseUnits } from 'ethers/lib/utils';
import { useTranslation } from 'react-i18next';

function Stake({ isCurrent, onUpdate }: { isCurrent: boolean; onUpdate?: () => void }) {
  const { t } = useTranslation();

  const { stake } = useStake();

  const { address, provider } = useWallet();

  const [value, setValue] = useState('');

  const { balanceOf } = useERC20Contract();

  const { data: catBalance, refresh } = useRequest(
    () => {
      return balanceOf(process.env.REACT_APP_CAT_CONTRACT_ADDRESS!);
    },
    {
      ready: !!provider,
      refreshDeps: [isCurrent],
    },
  );

  const { run, loading } = useRequest(
    () => {
      return stake(value);
    },
    {
      ready: !!address,
      manual: true,
      onSuccess() {
        refresh();
        onUpdate?.();
        message.success(t('Stake success', 'Stake success'));
      },
      onError(err) {
        console.log(err);

        message.error(t('Stake failed', 'Stake failed'));
      },
    },
  );

  function handleSummit() {
    if (parseUnits(value).gt(catBalance!)) {
      message.error(t('Insufficient balance', 'Insufficient balance'));

      return;
    }

    run();
  }

  return (
    <div className="px-[20px]">
      <div className="w-full justify-between items-center inline-flex">
        <div className="opacity-70 text-white text-xs font-normal font-['Prompt']">{t('Amount', 'Amount')}</div>
        <div>
          <span className="text-purple-700 text-[10px] font-normal font-['Prompt']">{t('Available', 'Available')}</span>
          <span className="text-white text-[10px] font-normal font-['Prompt']">
            <EthersValue value={catBalance} balance></EthersValue>
          </span>
          <span className="text-purple-700 text-[10px] font-normal font-['Prompt']"> CAT</span>
        </div>
      </div>

      <div className="flex flex-col gap-y-[10px] mt-[10px]">
        <ConfigProvider
          theme={{
            components: {
              Input: {
                colorBgContainer: '#1B153A',
                colorBorder: '#1B153A',
                colorText: '#fff',
              },
            },
            token: {
              colorPrimary: '#7F68E2',

              controlHeight: 50,
              fontSize: 20,
            },
          }}
        >
          <Input
            value={value}
            disabled={loading}
            placeholder={t('Enter the amount', 'Enter the amount')}
            onChange={(e) => {
              setValue(e.target.value);
            }}
            onBlur={(e) => {
              setValue(e.target.value.replace(/[^\d.]/g, ''));
            }}
          ></Input>
        </ConfigProvider>

        <ConnectButton>
          <ConfigProvider
            theme={{
              components: {
                Button: {
                  colorBgContainerDisabled: '#626262',
                  borderColorDisabled: '#626262',
                },
              },
              token: {
                colorPrimary: '#7F68E2',

                controlHeight: 50,
                fontSize: 20,
              },
            }}
          >
            <ERC20ApproveButton
              allowance={`${value}`}
              tokenAddress={process.env.REACT_APP_CAT_CONTRACT_ADDRESS}
              spenderAddress={process.env.REACT_APP_CAT_STAKE_CONTRACT_ADDRESS}
            >
              <Button
                disabled={!+value}
                type="primary"
                block
                className="mt-[10px]"
                loading={loading}
                onClick={() => {
                  handleSummit();
                }}
              >
                {t('Submit', 'Submit')}
              </Button>
            </ERC20ApproveButton>
          </ConfigProvider>
        </ConnectButton>
      </div>
    </div>
  );
}

function UnStake({ isCurrent, onUpdate }: { isCurrent: boolean; onUpdate?: () => void }) {
  const { unStake, getCostSavings } = useStake();

  const { address, provider } = useWallet();

  const [value, setValue] = useState('');

  const { t } = useTranslation();

  const { data: costSavings, refresh } = useRequest(
    () => {
      return getCostSavings(address!);
    },
    {
      ready: !!provider,
      refreshDeps: [isCurrent],
    },
  );

  const { run, loading } = useRequest(
    () => {
      return unStake(value);
    },
    {
      ready: !!provider,
      manual: true,
      onSuccess() {
        refresh();
        onUpdate?.();
        message.success(t('UnStake success', 'UnStake success'));
      },
      onError() {
        message.error(t('UnStake failed', 'UnStake failed'));
      },
    },
  );

  function handleSummit() {
    if (parseUnits(value).gt(costSavings!.stakeBalance!)) {
      message.error(t('Insufficient balance', 'Insufficient balance'));

      return;
    }

    run();
  }

  return (
    <div className="px-[20px]">
      <div className="w-full justify-between items-center inline-flex">
        <div className="opacity-70 text-white text-xs font-normal font-['Prompt']">{t('Amount', 'Amount')}</div>
        <div>
          <span className="text-purple-700 text-[10px] font-normal font-['Prompt']">{t('Available', 'Available')}</span>
          <span className="text-white text-[10px] font-normal font-['Prompt']">
            <EthersValue value={costSavings?.stakeBalance}></EthersValue>
          </span>
          <span className="text-purple-700 text-[10px] font-normal font-['Prompt']"> CAT</span>
        </div>
      </div>

      <div className="flex flex-col gap-y-[10px] mt-[10px]">
        <ConfigProvider
          theme={{
            components: {
              Input: {
                colorBgContainer: '#1B153A',
                colorBorder: '#1B153A',
                colorText: '#fff',
              },
            },
            token: {
              colorPrimary: '#7F68E2',

              controlHeight: 50,
              fontSize: 20,
            },
          }}
        >
          <Input
            value={value}
            disabled={loading}
            placeholder={t('Enter the amount', 'Enter the amount')}
            onChange={(e) => {
              setValue(e.target.value);
            }}
            onBlur={(e) => {
              setValue(e.target.value.replace(/[^\d.]/g, ''));
            }}
          ></Input>
        </ConfigProvider>

        <ConnectButton>
          <ConfigProvider
            theme={{
              components: {
                Button: {
                  colorBgContainerDisabled: '#626262',
                  borderColorDisabled: '#626262',
                },
              },
              token: {
                colorPrimary: '#CE0056',

                controlHeight: 50,
                fontSize: 20,
              },
            }}
          >
            <Button
              disabled={!+value}
              type="primary"
              block
              className="mt-[10px]"
              loading={loading}
              onClick={() => {
                handleSummit();
              }}
            >
              {t('UnStake', 'UnStake')}
            </Button>
          </ConfigProvider>
        </ConnectButton>
      </div>
    </div>
  );
}

export default function () {
  const { getTotalStaked, getCostSavings, getDiscount } = useStake();

  const { address, provider } = useWallet();

  const { data: totalStaked, refresh: refreshTotalStaked } = useRequest(
    () => {
      return getTotalStaked();
    },
    {
      ready: !!provider,
    },
  );

  const { data: costSavings, refresh: refreshCostSavings } = useRequest(
    () => {
      return getCostSavings(address!);
    },
    {
      ready: !!provider,
    },
  );

  const { data: discount, refresh: refreshDiscount } = useRequest(
    () => {
      return getDiscount(address!);
    },
    {
      ready: !!provider,
    },
  );

  const [key, setKey] = useState('1');

  const { t } = useTranslation();

  const items: TabsProps['items'] = [
    {
      key: '1',
      label: t('Stake', 'Stake'),
      children: (
        <Stake
          isCurrent={key === '1'}
          onUpdate={() => {
            refreshTotalStaked();
            refreshCostSavings();
            refreshDiscount();
          }}
        />
      ),
    },
    {
      key: '2',
      label: t('UnStake', 'UnStake'),
      children: (
        <UnStake
          isCurrent={key === '2'}
          onUpdate={() => {
            refreshTotalStaked();
            refreshCostSavings();
            refreshDiscount();
          }}
        />
      ),
    },
  ];

  return (
    <div
      className="w-screen h-screen overflow-auto pb-[30px]"
      style={{
        // backgroundImage: 'url(/static/images/default-background.png)',
        background: '#0F0B23',
        backgroundSize: '100% 100%',
      }}
    >
      <Header></Header>

      <div className="w-[410px] md:w-full mx-auto mt-[88px] md:px-[20px]">
        <div className="flex justify-start items-center gap-x-[10px]">
          <img className="w-[36px] h-[36px]" src="/static/images/icon/cat.png" />
          <div className="flex-col justify-center items-start inline-flex">
            <div className="justify-start items-center gap-2 inline-flex">
              <div className="text-white text-xl font-medium font-['Prompt']">{t('CAT Staking', 'CAT Staking')}</div>
            </div>
            <div className="text-center text-white text-xs font-normal font-['Prompt'] leading-3">
              {t('Reduce Your Transaction Costs', 'Reduce Your Transaction Costs')}
            </div>
          </div>
        </div>

        <div className="mt-[20px]">
          <div className="flex justify-between items-center">
            <div className="w-[121px] h-[42px] flex-col justify-start items-start inline-flex">
              <div className="opacity-70 text-white text-[10px] font-normal font-['Prompt']">
                {t('Last Price (USD)', 'Last Price (USD)')}
              </div>
              <div className="justify-center items-center gap-1 inline-flex">
                <div className="text-violet-500 text-lg font-normal font-['Prompt']">-</div>
                <div className="justify-center items-center gap-1 flex">
                  <div className="text-green-600 text-xs font-semibold font-['Mukta'] leading-[18px]">(-%)</div>
                </div>
              </div>
            </div>
            <div className="w-[60px] h-[42px] flex-col justify-start items-end inline-flex">
              <div className="opacity-70 text-white text-[10px] font-normal font-['Prompt']">
                {t('Total Staked', 'Total Staked')}
              </div>
              <div className="justify-center items-center gap-1 inline-flex">
                <div className="text-violet-500 text-lg font-normal font-['Prompt']">
                  <EthersValue value={totalStaked}></EthersValue>
                </div>
              </div>
            </div>
          </div>
        </div>

        <div className="mt-[20px]">
          <div className="flex justify-between items-center">
            <div className="w-[121px] h-[42px] flex-col justify-start items-start inline-flex">
              <div className="opacity-70 text-white text-[10px] font-normal font-['Prompt']">
                {t('Your Staked', 'Your Staked')}
              </div>
              <div className="justify-center items-center gap-1 inline-flex">
                <div className="text-violet-500 text-lg font-normal font-['Prompt']">
                  <EthersValue value={costSavings?.stakeBalance}></EthersValue> CAT
                </div>
              </div>
            </div>
            <div className="w-[100px] h-[42px] flex-col justify-start items-end inline-flex">
              <div className="opacity-70 text-white text-[10px] font-normal font-['Prompt']">
                {t('Your Discount Level', 'Your Discount Level')}
              </div>
              <div className="justify-center items-center gap-1 inline-flex">
                <div className="text-violet-500 text-lg font-normal font-['Prompt']">
                  <EthersValue value={discount} unit="8" fixed={0}></EthersValue>%
                </div>
              </div>
            </div>
          </div>
        </div>

        <div className="w-full mt-[20px] py-[5px] rounded-[8px] border border-indigo-950 border-solid flex-col justify-start items-center inline-flex">
          <div className="opacity-70 text-white text-[10px] font-normal font-['Prompt']">
            {t('Fee saved', 'Fee saved')}
          </div>
          <div className="justify-center items-center gap-1 inline-flex">
            <div className="text-violet-500 text-lg font-normal font-['Prompt']">
              <EthersValue value={costSavings?.feeSaved}></EthersValue> USD
            </div>
          </div>
        </div>

        <div className="w-full mt-[20px] py-[5px] rounded-[8px] border border-indigo-950 border-solid flex-col justify-start items-center inline-flex">
          <Tabs
            className="w-full"
            centered
            defaultActiveKey="1"
            items={items}
            onChange={(key) => {
              setKey(key);
            }}
          />
        </div>

        <Rate />
      </div>
    </div>
  );
}

function Rate() {
  const { getDiscountConfig } = useStake();

  const { provider } = useWallet();

  const { data } = useRequest(
    () => {
      return getDiscountConfig();
    },
    {
      ready: !!provider,
      onSuccess(res) {
        console.log(res);
      },
    },
  );

  const { t } = useTranslation();

  return (
    <div className="w-full mt-[20px] h-[131px] p-2.5 bg-indigo-950 rounded-[8px] flex-col justify-start items-start gap-2.5 inline-flex">
      <div className="self-stretch h-[111px] flex-col justify-start items-start gap-[3px] flex">
        <div className="self-stretch justify-between items-start inline-flex">
          <div className="opacity-70 text-white text-[10px] font-normal font-['Prompt']">
            $CAT {t('Stake amount', 'Stake amount')}
          </div>
          <div className="opacity-70 text-white text-[10px] font-normal font-['Prompt']">
            {t('Discount Tiers', 'Discount Tiers')}
          </div>
        </div>

        {data?.map((item, index) => {
          return (
            <div key={index} className="self-stretch justify-between items-start inline-flex">
              <div className="text-white text-sm font-normal font-['Prompt']">
                <EthersValue value={item.stakeAmount}></EthersValue> CAT
              </div>
              <div className="text-white text-sm font-normal font-['Prompt']">
                <EthersValue value={item.discountRate} unit="8" fixed={0}></EthersValue>%
              </div>
            </div>
          );
        })}
      </div>
    </div>
  );
}
