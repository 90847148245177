import { useWallet } from '@/common/hooks/wagmi/useWallet';
import { BigNumber, ethers } from 'ethers';
import { parseEther } from 'ethers/lib/utils';
import { ERC20__factory } from '../typechain';

export function useERC20Contract() {
  const { provider, address } = useWallet();

  const getContract = (tokenAddress: string) => {
    console.log('provider', provider);

    return ERC20__factory.connect(tokenAddress, provider as any);
  };

  async function getAllowance(tokenAddress: string, spender: string) {
    return getContract(tokenAddress).allowance(address!, spender);
  }

  async function balanceOf(tokenAddress: string) {
    return getContract(tokenAddress).balanceOf(address!);
  }

  async function approve(tokenAddress: string, spender: string, amount: string) {
    const allowance = await getAllowance(tokenAddress, spender);

    let value = parseEther(amount);

    if (allowance.lt(value)) {
      value = value.sub(allowance);
    }

    const tx = await getContract(tokenAddress).approve(spender, value);
    await tx.wait();

    return tx;
  }

  async function hasApproved(tokenAddress: string, spender: string, amount = '1000') {
    return getAllowance(tokenAddress, spender).then((v) => v.gte(parseEther(amount)));
  }

  return {
    getAllowance,
    balanceOf,
    approve,
    hasApproved,
  };
}
