import React, { useMemo } from 'react';
import { BigNumberish } from 'ethers';
import { formatUnits } from 'ethers/lib/utils';

export const EthersValue = (props: {
  value?: BigNumberish;
  fixed?: number;
  placeholder?: string;
  loading?: boolean;
  unit?: string;
  balance?: boolean;
}) => {
  const value = formatUnits(props.value || '0', props.unit);

  const { fixed = 2, placeholder = '-', balance = false } = props;

  const displayValue = useMemo(() => {
    if (!balance) {
      return (+value).toFixed(fixed);
    }
    const factor = Math.pow(10, fixed);

    return (Math.floor(+value * factor) / factor).toFixed(fixed);
  }, [balance, fixed, value]);

  return <span title={value}>{props.loading ? '-' : +displayValue ? displayValue : placeholder ?? displayValue}</span>;
};
