import { useERC20Contract } from '@/common/contracts/hooks/useERC20Contract';
import { useWallet } from '@/common/hooks/wagmi/useWallet';
import { ConnectButton } from '@/components/App/ConnectButton';
import ERC20ApproveButton from '@/components/App/ERC20ApproveButton';
import { EthersValue } from '@/components/App/EthersValue';
import { useRequest } from 'ahooks';
import { ConfigProvider, Button, message, Input, List, Tooltip } from 'antd';
// import { t } from 'i18next';
import { Trans, useTranslation } from 'react-i18next';
import { useEffect, useMemo, useState } from 'react';
import { AppModal, Field } from './Common';
import { useTokenContract } from './useTokenContract';
import { getClaimData, getClaimHistory, getStakeInfo } from '@/service/farm';
import { BigNumber } from 'ethers';
import dayjs from 'dayjs';
import { QuestionCircleOutlined } from '@ant-design/icons';
import { formatUnits, parseUnits } from 'ethers/lib/utils';

function formatTime(time: number) {
  return dayjs(time * 1000).format('YYYY-MM-DD HH:mm');
}

export function ModalHeader({ title }: { title: string }) {
  return (
    <div className="text-center mb-4">
      <h3 className="mb-4">{title}</h3>
      <div style={{ height: '1px', backgroundColor: '#8069E3', width: '100%' }}></div>
    </div>
  );
}

function ClaimModal({
  open,
  onClose,
  onSuccess,
  claimable,
}: {
  open: boolean;
  onClose: () => void;
  onSuccess: () => void;
  claimable?: BigNumber;
}) {
  const { provider, address } = useWallet();

  const { claim } = useTokenContract();

  const { t } = useTranslation();

  const { loading, run } = useRequest(
    async () => {
      const signData = await getClaimData({
        address: address!,
        farmAddr: process.env.REACT_APP_FARM_STAKE_CONTRACT_ADDRESS!,
      });

      return claim({
        maxBalance: signData.data.max_balance,
        maxReward: signData.data.max_reward,
        deadline: signData.data.deadline,
        signature: signData.data.signature,
      });
    },
    {
      manual: true,
      ready: !!provider,
      onSuccess() {
        onSuccess?.();
        message.success(t('Claim success', 'Claim success'));
      },
      onError() {
        message.error(t('Claim failed', 'Claim failed'));
      },
    },
  );

  return (
    <AppModal
      title=<ModalHeader title={t('Claim', 'Claim')} />
      open={open}
      onCancel={onClose}
      footer={
        <ConfigProvider
          theme={{
            components: {
              Button: {
                colorBgContainerDisabled: '#626262',
                borderColorDisabled: '#626262',
              },
            },
            token: {
              colorPrimary: '#0AAE55',
              controlHeight: 50,
            },
          }}
        >
          <Button
            type="primary"
            block
            loading={loading}
            onClick={() => {
              run();
            }}
          >
            {t('Confirm', 'Confirm')}
          </Button>
        </ConfigProvider>
      }
    >
      <div className="my-[10px] ">
        {t('You’ll claim all', 'You’ll claim all')}
        <span className="text-[#8069E3] mx-[5px] text-[18px]">
          <EthersValue value={claimable} balance></EthersValue>
        </span>{' '}
        $eCAT.
      </div>
    </AppModal>
  );
}

function StakeModal({ open, onClose, onSuccess }: { open: boolean; onClose: () => void; onSuccess: () => void }) {
  const [value, setValue] = useState('');

  const [stakeInfo, setStakeInfo] = useState({ duration: 0, expectedProfit: '0' });

  const { provider } = useWallet();

  const { stake } = useTokenContract();

  const { balanceOf } = useERC20Contract();

  const { t } = useTranslation();

  const { data: eCatBalance } = useRequest(
    async () => {
      return balanceOf(process.env.REACT_APP_ECAT_CONTRACT_ADDRESS!);
    },
    {
      ready: !!provider,
    },
  );

  const { loading, run } = useRequest(
    async () => {
      return stake(value);
    },
    {
      manual: true,
      ready: !!provider,
      onSuccess() {
        onSuccess?.();
        message.success(t('Stake success', 'Stake success'));
      },
      onError(err) {
        console.log('stake error', err);
        message.error(t('Stake failed', 'Stake failed'));
      },
    },
  );

  function handleSummit() {
    if (parseUnits(value).gt(eCatBalance!)) {
      message.error(t('Insufficient balance', 'Insufficient balance'));

      return;
    }

    run();
  }

  const calculateEcatStaking = (stakedEcat: number) => {
    let apy = 0;

    const duration = stakedEcat >= 1500 ? 7 : 5;

    let expectedProfit = 0;

    if (stakedEcat <= 1000) {
      apy = 20;
      expectedProfit = ((stakedEcat * apy) / 100 / 365) * duration;
    } else if (stakedEcat <= 1500) {
      apy = 20; // 初始1000的年化
      const extraApy = 30; // 额外500的年化
      expectedProfit = (((1000 * apy) / 100 + ((stakedEcat - 1000) * extraApy) / 100) / 365) * duration;
    } else {
      apy = 20; // 初始1000的年化
      const secondTierApy = 30; // 额外500的年化

      const extraApy = 100; // 超过1500的部分年化
      expectedProfit =
        (((1000 * apy) / 100 + (500 * secondTierApy) / 100 + ((stakedEcat - 1500) * extraApy) / 100) / 365) * duration;
    }

    return {
      duration,
      expectedProfit: expectedProfit.toFixed(2), // 四舍五入到小数点后两位
    };
  };

  useEffect(() => {
    setStakeInfo(calculateEcatStaking(+value));
  }, [value]);

  return (
    <AppModal
      title=<ModalHeader title={t('Stake', 'Stake')} />
      open={open}
      onCancel={onClose}
      footer={
        <ConfigProvider
          theme={{
            components: {
              Button: {
                colorBgContainerDisabled: '#626262',
                borderColorDisabled: '#626262',
              },
            },
            token: {
              colorPrimary: '#0AAE55',
              controlHeight: 50,
            },
          }}
        >
          <ERC20ApproveButton
            tokenAddress={process.env.REACT_APP_ECAT_CONTRACT_ADDRESS}
            spenderAddress={process.env.REACT_APP_FARM_STAKE_CONTRACT_ADDRESS}
            allowance={`${value}`}
          >
            <Button
              type="primary"
              block
              loading={loading}
              onClick={() => {
                handleSummit();
              }}
            >
              {t('Stake', 'Stake')}
            </Button>
          </ERC20ApproveButton>
          <div className="justify-between items-center flex">
            <div className="flex justify-start items-center gap-x-[5px]">
              <div className="my-[10px] text-opacity-75">{t('Duration', 'Duration')}:</div>
              <div>{stakeInfo.duration} D</div>
            </div>

            <div className="flex justify-start items-center gap-x-[5px]">
              <div className="my-[10px] text-opacity-75">{t('Exp Ret', 'Exp Ret')}:</div>
              <div>{stakeInfo.expectedProfit}</div>
            </div>

            <div className="flex justify-start items-center gap-x-[5px]">
              <div className="my-[10px] text-opacity-75">{t('Balance', 'Balance')}:</div>
              <div>
                <EthersValue value={eCatBalance} balance></EthersValue>
              </div>
            </div>
          </div>
        </ConfigProvider>
      }
    >
      {/*<div className="my-[10px] text-opacity-75">{t('Amount', 'Amount')}</div>*/}
      <ConfigProvider
        theme={{
          components: {
            Input: {
              colorBgContainer: '#1B153A',
              colorBorder: '#1B153A',
              colorText: '#fff',
            },
          },
          token: {
            colorPrimary: '#7F68E2',
            controlHeight: 50,
          },
        }}
      >
        <Input
          value={value}
          disabled={loading}
          placeholder={t('Enter the amount', 'Enter the amount')}
          onChange={(e) => {
            setValue(e.target.value);
          }}
          onBlur={(e) => {
            setValue(e.target.value.replace(/[^\d.]/g, ''));
          }}
        ></Input>
      </ConfigProvider>
    </AppModal>
  );
}

// suffix={<Button type="link">All</Button>}

export function Farm() {
  const [stakeOpen, setStakeOpen] = useState(false);

  const [claimOpen, setClaimOpen] = useState(false);

  const { provider, address } = useWallet();

  const { getStakeInfo: getContractStakeInfo } = useTokenContract();

  const { t } = useTranslation();

  const { data, refreshAsync } = useRequest(
    () => {
      return getStakeInfo({ address: address!, farmAddr: process.env.REACT_APP_FARM_STAKE_CONTRACT_ADDRESS! });
    },
    { ready: !!provider && !!address },
  );

  const { data: contractStakeInfo, refresh: refreshContractStakeInfo } = useRequest(
    () => {
      return getContractStakeInfo(address!);
    },
    { ready: !!provider && !!address },
  );

  const {
    data: historyList,
    refresh: refreshHistoryList,
    loading: historyLoading,
  } = useRequest(
    () => {
      return getClaimHistory({ address: address!, farmAddr: process.env.REACT_APP_FARM_STAKE_CONTRACT_ADDRESS! });
    },
    { ready: !!provider && !!address },
  );

  const [lpInfoOpen, setLpInfoOpen] = useState(false);

  const staked = useMemo(() => {
    try {
      const val = BigNumber.from(contractStakeInfo!.balance).sub(contractStakeInfo!.balanceClaimed);

      if (val.lt(0)) return BigNumber.from(0);

      return val;
    } catch (err) {
      return BigNumber.from(0);
    }
  }, [data, contractStakeInfo]);

  const claimable = useMemo(() => {
    try {
      const val = BigNumber.from(data?.data?.claimable_reward)
        .add(BigNumber.from(data?.data?.claimable_balance))
        .sub(contractStakeInfo!.balanceClaimed.add(contractStakeInfo!.rewardClaimed));

      if (val.lt(0)) return BigNumber.from(0);

      return val;
    } catch (err) {
      return BigNumber.from(0);
    }
  }, [contractStakeInfo, data]);

  const canClaim = useMemo(() => {
    try {
      return claimable.gt(parseUnits('0.01'));
    } catch (err) {
      console.log('err-------->', err);

      return false;
    }
  }, [claimable]);

  return (
    <div>
      <div className="mt-[30px] mb-[40px] text-[#777E90] text-center text-[16px]">
        {t('Stake $eCAT to earn $eCAT', 'Stake $eCAT to earn $eCAT')}
      </div>
      <div className="border-[#241C4C] border border-solid p-[20px] rounded">
        <div className="flex justify-between items-center md:flex-wrap md:justify-around md:gap-x-[10px]">
          <img src="/static/images/icon/eCat.png" className="w-[48px] md:hidden" />

          <Field title={t('Staked', 'Staked')} value={<EthersValue value={staked}></EthersValue> || '-'}></Field>
          <Field
            title={t('Earned', 'Earned')}
            value={parseFloat(data?.data?.earned as string).toFixed(2) || '-'}
          ></Field>
          <Field
            title={
              <div className="flex justify-center items-center">
                <div>{t('APY', 'APY')}</div>
                <QuestionCircleOutlined
                  className="ml-[5px] cursor-pointer"
                  onClick={() => {
                    setLpInfoOpen(true);
                  }}
                />
              </div>
            }
            value={data?.data?.apy || '-'}
          ></Field>
          <Field title={t('Period', 'Period')} value={data?.data?.period || '-'}></Field>
          <Field
            title={t('Claimable', 'Claimable')}
            value={<EthersValue value={claimable} balance></EthersValue>}
          ></Field>
        </div>

        <div className="flex justify-between items-center mt-[30px] gap-x-[15px]">
          <ConnectButton>
            <ConfigProvider
              theme={{
                components: {
                  Button: {
                    colorBgContainerDisabled: '#626262',
                    borderColorDisabled: '#626262',
                  },
                },
                token: {
                  colorPrimary: '#0AAE55',
                  controlHeight: 50,
                },
              }}
            >
              <Button
                type="primary"
                block
                onClick={() => {
                  setStakeOpen(true);
                }}
              >
                {t('Stake', 'Stake')}
              </Button>
            </ConfigProvider>
            <ConfigProvider
              theme={{
                components: {
                  Button: {
                    colorBgContainerDisabled: '#626262',
                    borderColorDisabled: '#626262',
                  },
                },
                token: {
                  colorPrimary: '#7F68E2',
                  controlHeight: 50,
                },
              }}
            >
              <Button
                type="primary"
                block
                disabled={!canClaim}
                onClick={() => {
                  setClaimOpen(true);
                }}
              >
                {t('Claim all', 'Claim all')}
              </Button>
            </ConfigProvider>
          </ConnectButton>
        </div>
      </div>

      <div className="mt-[10px]">
        <h3>
          {t('History', 'History')}
          <Tooltip
            title={t('History synchronization may take 1~2 minutes', 'History synchronization may take 1~2 minutes')}
          >
            <QuestionCircleOutlined className="ml-[5px]" />
          </Tooltip>
        </h3>
      </div>

      <div className="bg-[#1B153A] rounded p-[10px] mt-[10px]  gap-x-[5px]">
        <div className=" flex justify-between items-center text-[15px]">
          <div className="w-1/5 text-center">{t('Amount', 'Amount')}</div>
          <div className="w-1/5 text-center">{t('Period', 'Period')}</div>
          <div className="w-1/5 text-center">{t('APY', 'APY')}</div>
          <div className="w-1/5 text-center">{t('End', 'End')}</div>
          <div className="w-1/5 text-center">{t('Rewards', 'Rewards')}</div>
        </div>

        <List
          className="mt-[15px]"
          loading={historyLoading}
          dataSource={historyList?.data}
          renderItem={(item) => (
            <List.Item>
              <div className=" flex justify-between items-center w-full gap-x-[5px]">
                <div className="w-1/5 text-center">{item.amount}</div>
                <div className="w-1/5 text-center">{item.period}</div>
                <div className="w-1/5 text-center">{`${item.apy * 100} %`}</div>
                <div className="w-1/5 text-center">{formatTime(+item.end_time)}</div>
                <div className="w-1/5 text-center">{item.rewards?.toFixed(4)}</div>
              </div>
            </List.Item>
          )}
        />
      </div>

      <StakeModal
        open={stakeOpen}
        onClose={() => {
          setStakeOpen(false);
        }}
        onSuccess={() => {
          setStakeOpen(false);
          refreshAsync();
          refreshHistoryList();
        }}
      />

      <ClaimModal
        open={claimOpen}
        claimable={claimable}
        onClose={() => {
          setClaimOpen(false);
        }}
        onSuccess={() => {
          setClaimOpen(false);
          refreshAsync();
          refreshHistoryList();
          refreshContractStakeInfo();
        }}
      />
      <LpInfoModal
        open={lpInfoOpen}
        onClose={() => {
          setLpInfoOpen(false);
        }}
      ></LpInfoModal>
    </div>
  );
}

function LpInfoModal({ open, onClose }: { open: boolean; onClose: () => void }) {
  const { address } = useWallet();

  const { t } = useTranslation();

  const { loading, data } = useRequest(
    async () => {
      return getStakeInfo({ address: address!, farmAddr: process.env.REACT_APP_FARM_STAKE_CONTRACT_ADDRESS! });
    },
    {
      ready: !!address,
    },
  );

  const level = useMemo(() => {
    try {
      const staked = +data!.data.staked;

      let levelArr: any = [];

      if (staked >= 1) {
        levelArr = [
          ...levelArr,
          <span key={1} className="text-[#F3DF68]">
            Level1
          </span>,
          <span key={2}>+</span>,
        ];
      }

      if (staked >= 1000) {
        levelArr = [
          ...levelArr,
          <span key={3} className="text-[#F3DF68]">
            Level2
          </span>,
          <span key={4}>+</span>,
        ];
      }

      if (staked >= 1500) {
        levelArr = [
          ...levelArr,
          <span key={5} className="text-[#F3DF68]">
            Level3
          </span>,
        ];
      }

      if (levelArr.length) return levelArr;

      return '-';
    } catch (err) {
      return '-';
    }
  }, [data]);

  return (
    <AppModal title=<ModalHeader title={t('Stake', 'Stake')} /> open={open} onCancel={onClose} footer={null}>
      <div className="bg-[#1B153A] rounded p-[10px] mt-[10px]">
        <div className=" flex justify-between items-center w-full text-[12px]">
          <div>{t('Level', 'Level')}</div>
          <div>{t('Grand total $eCAT amount', 'Grand total $eCAT amount')}</div>
          <div>{t('APY', 'APY')}</div>
        </div>

        <List
          className="mt-[15px]"
          loading={loading}
          dataSource={data?.data.conf}
          renderItem={(item) => (
            <List.Item>
              <div className=" flex justify-between items-center w-full">
                <div>{item.level}</div>
                <div>{item.range.join('~')}</div>
                <div>{item.apy * 100} % </div>
              </div>
            </List.Item>
          )}
        />
      </div>
      <div className="my-[10px]">Your reward = {level}</div>
      <div className="text-[#8D8A9C]">
        <Trans i18nKey={'stake-example'}>
          Eg. If you stake 2770eCAT ,you’ll earn (1000*20%/365*7)+(500*30%/365*7)+(1270/365*7)≈31.07 eCat
        </Trans>
      </div>
    </AppModal>
  );
}
