import { CommonHookParams } from '@/common/types/Wagmi';
import { useNetwork, useSwitchNetwork } from 'wagmi';

export function useChain({ chainId, onError, onMutate, onSettled, onSuccess }: CommonHookParams = {}) {
  const { chains, error, isLoading, pendingChainId, switchNetwork } = useSwitchNetwork({
    chainId,
    onError,
    onMutate,
    onSettled,
    onSuccess,
  });

  const { chain } = useNetwork();

  return {
    chain,
    chains,
    error,
    isLoading,
    pendingChainId,
    switchNetwork,
  };
}
