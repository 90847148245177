import { create } from 'zustand';
import { ethers } from 'ethers';
import { parseBytes32String } from 'ethers/lib/utils';

type Mapping = {
  Test: string;
};

type State = {
  mapping: Mapping | null;
  init: () => Promise<void>;
};

export const useNameServiceStore = create<State>((set) => ({
  mapping: null as Mapping | null,
  async init() {
    // const nameService = NameServiceInterface__factory.connect(
    //   process.env.REACT_APP_NAME_SERVICE!,
    //   ethers.Wallet.createRandom(),
    // );
    // const services = await nameService.listSingleEntries();
    // const mapping = services.reduce(
    //   (mapping, item) => Object.assign(mapping, { [parseBytes32String(item.name)]: item.addr }),
    //   {} as any,
    // );
    // nameServiceStore.setMapping(mapping);
    // set()
  },
}));
