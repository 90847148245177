import { shortenAddress } from '@/common/utils';
import { HashIdenticon } from '../HashIdenticon';
import { ConfigProvider, Dropdown, MenuProps } from 'antd';
import { DownOutlined } from '@ant-design/icons';
import { useWallet } from '@/common/hooks/wagmi/useWallet';
import { Preference } from '@/common/preferences';

export function UserInfo() {
  const { address, disconnect } = useWallet();

  function handleDisconnect() {
    disconnect();
  }

  const items: MenuProps['items'] = [
    {
      key: '1',
      label: (
        <div
          className="w-full text-center"
          onClick={() => {
            handleDisconnect();
          }}
        >
          Disconnect
        </div>
      ),
    },
  ];

  return (
    <ConfigProvider
      theme={{
        token: {
          colorPrimary: '#FE7122',
          borderRadiusSM: 10,
          borderRadius: 10,
          borderRadiusLG: 10,
        },
      }}
    >
      <Dropdown menu={{ items }}>
        <div className="flex justify-end items-center gap-x-[10px] border-[#241C4C] border-solid border px-[10px] py-[10px] cursor-pointer text-white text-[14px]">
          {/* {address && (
            <HashIdenticon width={20} hash={address!}></HashIdenticon>
          )} */}
          <i className="bufficon-v2 bi2-wallet-2-line1 proxy-icon"></i>

          <span className="font-bold">{shortenAddress(address)}</span>
          <DownOutlined   />
        </div>
      </Dropdown>
    </ConfigProvider>
  );
}
