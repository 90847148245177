import { useWallet } from '@/common/hooks/wagmi/useWallet';
import Header from '@/components/UI/Header';
import { Button, ConfigProvider, message } from 'antd';
import { useMemo, useState } from 'react';
import { useFinance } from './useFinance';
import { useRequest } from 'ahooks';
import { claim as claimRequest } from '@/service';
import { ConnectButton } from '@/components/App/ConnectButton';
import { EthersValue } from '@/components/App/EthersValue';
import { BigNumber } from 'ethers';
import { useTranslation } from 'react-i18next';

export default function () {
  const { claim, getClaimed } = useFinance();

  const { address, provider } = useWallet();

  const { t } = useTranslation();

  const { data: claimInfo, refresh: refreshClaimInfo } = useRequest(
    async () => {
      const res = await claimRequest({ address: address! });

      return res.data;
    },
    {
      ready: !!address,
    },
  );

  const { data: claimed, refresh: refreshClaimed } = useRequest(
    async () => {
      const res = await getClaimed(address!);

      return res;
    },
    {
      ready: !!provider,
    },
  );

  const available = useMemo(() => {
    try {
      const value = BigNumber.from(claimInfo?.maxAmount).sub(claimed!);

      if (value.gt(0)) {
        return value;
      }
    } catch (err) {
      return '';
    }
  }, [claimInfo?.maxAmount, claimed]);

  const { run, loading } = useRequest(
    async () => {
      const res = await claimRequest({ address: address! });

      return claim(res.data);
    },
    {
      manual: true,

      onSuccess() {
        message.success(t('Claim success', 'Claim success'));
        refreshClaimInfo();
        refreshClaimed();
      },
      onError() {
        message.error(t('Claim failed', 'Claim failed'));
      },
    },
  );

  const goTrade = () => {
    window.location.href = `${process.env.REACT_APP_ORIGIN_WEB_URL}/trade`;
  };

  return (
    <div
      className="w-screen h-screen pb-[30px]"
      style={{
        // backgroundImage: 'url(/static/images/default-background.png)',
        background: '#0F0B23',
        backgroundSize: '100% 100%',
      }}
    >
      <Header></Header>

      <div className="max-w-[640px] md:w-full mx-auto flex justify-between items-start gap-x-[53px] mt-[80px] md:flex-wrap md:justify-center md:px-[20px]">
        <img src="/static/images/icon/cat-lg.png" className="md:w-[100px]" />

        <div className=" w-[410px] h-[251.11px] relative md:w-full">
          <div className="text-white text-xl font-medium font-['Prompt']">{t('Trade to Earn', 'Trade to Earn')}</div>

          <div className="w-[410px] md:w-full h-[140px] justify-start items-start gap-2.5 inline-flex flex-wrap mt-[10px]">
            <div className="w-1/2 px-5 py-[5px] rounded border border-solid border-[#241C4C] flex-col justify-start items-center gap-2.5 inline-flex">
              <div className="py-1 justify-start items-center gap-2.5 inline-flex">
                <div className="opacity-70 text-white text-[10px] font-normal font-['Prompt'] leading-[10px]">
                  {t('Total Transaction Fee Cost', 'Total Transaction Fee Cost')}
                </div>
              </div>
              <div className="justify-center items-center gap-1 inline-flex">
                <div className="text-white text-lg font-normal font-['Prompt']">
                  <EthersValue value={claimInfo?.tradedFee} balance></EthersValue> USDT
                </div>
              </div>
            </div>

            <div className="w-1/2 grow shrink basis-0 h-[65px] px-5 py-[5px] rounded border border-solid border-[#241C4C] flex-col justify-start items-center gap-2.5 inline-flex">
              <div className="py-1 justify-start items-center gap-2.5 inline-flex">
                <div className="opacity-70 text-white text-[10px] font-normal font-['Prompt'] leading-[10px]">
                  {t('Available', 'Available')} eCAT
                </div>
              </div>
              <div className="justify-center items-center gap-1 inline-flex">
                <div className="text-white text-lg font-normal font-['Prompt']">
                  <EthersValue value={available} balance></EthersValue> <span className="md:hidden">eCAT</span>
                </div>
              </div>
            </div>

            <div className="w-full h-[65px] px-5 py-[5px] rounded border border-solid border-[#241C4C] flex-col justify-start items-center gap-2.5 inline-flex">
              <div className="py-1 justify-start items-center gap-2.5 inline-flex">
                <div className="opacity-70 text-white text-[10px] font-normal font-['Prompt'] leading-[10px]">
                  {t('Total Earned', 'Total Earned')} eCAT
                </div>
              </div>
              <div className="justify-center items-center gap-1 inline-flex">
                <div className="text-white text-lg font-normal font-['Prompt']">
                  <EthersValue value={claimInfo?.maxAmount} balance></EthersValue>{' '}
                  <span className="md:hidden">eCAT</span>
                </div>
              </div>
            </div>
          </div>
          <div className="w-full h-[50px] left-0 top-[201.11px]  justify-start items-start gap-5 inline-flex mt-[20px]">
            <ConnectButton>
              <ConfigProvider
                theme={{
                  components: {
                    Button: {
                      colorBgContainerDisabled: '#626262',
                      borderColorDisabled: '#626262',
                    },
                  },
                  token: {
                    colorPrimary: '#0AAE55',

                    controlHeight: 50,
                    fontSize: 20,
                  },
                }}
              >
                <Button
                  type="primary"
                  block
                  onClick={() => {
                    goTrade();
                  }}
                >
                  {t('Start Trading', 'Start Trading')}
                </Button>
              </ConfigProvider>
              <ConfigProvider
                theme={{
                  components: {
                    Button: {
                      colorBgContainerDisabled: '#626262',
                      borderColorDisabled: '#626262',
                    },
                  },
                  token: {
                    colorPrimary: '#7F68E2',

                    controlHeight: 50,
                    fontSize: 20,
                  },
                }}
              >
                <Button
                  type="primary"
                  block
                  ghost
                  loading={loading}
                  disabled={available ? !+available : true}
                  onClick={() => {
                    run();
                  }}
                >
                  {t('Claim', 'Claim')}
                </Button>
              </ConfigProvider>
            </ConnectButton>
          </div>
        </div>
      </div>
    </div>
  );
}
