import { ECatStake__factory } from '@/common/contracts/typechain';
import { useWallet } from '@/common/hooks/wagmi/useWallet';
import { parseUnits } from 'ethers/lib/utils';

export function useStake() {
  const { provider } = useWallet();

  function getContract() {
    return ECatStake__factory.connect(process.env.REACT_APP_ECAT_STAKE_CONTRACT_ADDRESS!, provider);
  }

  async function stake(amount: string) {
    const tx = await getContract().stake(parseUnits(amount));

    const res = await tx.wait();

    return res;
  }

  async function claim({ maxAmount, deadline, signature }: { maxAmount: string; deadline: string; signature: string }) {
    const tx = await getContract().claim(maxAmount, deadline, signature);

    const res = await tx.wait();

    return res;
  }

  async function getTotalStaked() {
    const res = await getContract().totalStaked();

    return res;
  }

  async function getCostSavings(address: string) {
    const res = await getContract().users(address);

    return res;
  }

  return {
    stake,
    claim,
    getTotalStaked,
    getCostSavings,
  };
}
