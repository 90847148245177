import { Preference } from '@/common/preferences';
import { useEffect } from 'react';

import { WalletPayload } from './useWallet';
import { useWalletStore } from '@/stores/useBitcoinWalletStore';

const bitcoin = window.okxwallet?.bitcoin || {};

export function useOkxWallet(payload?: WalletPayload) {
  const { setAccount, clearAccount } = useWalletStore();

  const connect = async () => {
    try {
      const result = await bitcoin.connect();

      if (!result) {
        return Promise.reject('No account');
      }

      setAccount(result);

      payload?.onConnect?.(result);

      return result;
    } catch (err) {
      payload?.onConnectError?.();

      return Promise.reject(err);
    }
  };

  function signMessage({ message }: { message: string }) {
    const account = Preference.getAccount();

    try {
      return bitcoin.signMessage(message, {
        from: account!.address,
      });
    } catch (e) {
      return Promise.reject(e);
    }
  }

  function disconnect() {
    payload?.onDisconnect?.();
    clearAccount();
  }

  // TODO - handle accountsChanged
  useEffect(() => {
    // const accountsChanged = (accounts: any) => {
    //   console.log('accounts', accounts);
    // };
    // bitcoin.on('accountsChanged', accountsChanged);
  }, []);

  return {
    signMessage,
    connect,
    disconnect,
  };
}
