import request from '@/common/request/whitelist';
import vaultRequest from '@/common/request/vault';

export const login = async (payload: {
  signedMessage: string;
  signature: string;
  inviteCode: string;
  signatureType: 'EVM' | 'BTC';
}) => {
  return request.post<{ account: number; token: string }>('/v1/accounts/login', payload);
};

export const auth = async (payload: { address: string; sign: string; code?: string }) => {
  return vaultRequest.post<{ account: number; token: string }>('/api/v1/auth', payload);
};
