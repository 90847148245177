import { ConnectButton } from '@/components/App/ConnectButton';
import ERC20ApproveButton from '@/components/App/ERC20ApproveButton';
import Header from '@/components/UI/Header';
import { useRequest } from 'ahooks';
import { Button, ConfigProvider, Input, List, Tabs, TabsProps, message } from 'antd';
import { t } from 'i18next';
import { useEffect, useMemo, useState } from 'react';
import { useVault } from './useVault';
import { useWallet } from '@/common/hooks/wagmi/useWallet';
import { EthersValue } from '@/components/App/EthersValue';
import { useERC20Contract } from '@/common/contracts/hooks/useERC20Contract';
import { formatUnits, parseUnits } from 'ethers/lib/utils';
// import dayjs from 'dayjs';
import Countdown from 'antd/es/statistic/Countdown';
import { isNumber } from 'lodash';
import { getWithdrawHistory } from '@/service/vault';
import { Trans } from 'react-i18next';
import { BigNumber, ethers } from 'ethers';

function Stake({ isCurrent, onUpdate }: { isCurrent: boolean; onUpdate: () => void }) {
  const [value, setValue] = useState('');

  const { balanceOf } = useERC20Contract();

  const { provider, address } = useWallet();

  const { deposit, shareToAssetsPrice, withdrawEpochsTimelock, pauseDeposit } = useVault();

  const { data: balance, refresh: refreshBalance } = useRequest(
    async () => {
      return balanceOf(process.env.REACT_APP_USDT_CONTRACT_ADDRESS!);
    },
    {
      ready: !!provider,
      refreshDeps: [isCurrent],
    },
  );

  const { data: cUsdtPrice } = useRequest(
    async () => {
      return shareToAssetsPrice();
    },
    {
      ready: !!provider,
      refreshDeps: [isCurrent],
    },
  );

  // const { data: timeLock } = useRequest(
  //   async () => {
  //     return withdrawEpochsTimelock();
  //   },
  //   {
  //     ready: !!provider,
  //     refreshDeps: [isCurrent],
  //     onSuccess(res) {
  //       console.log('timeLock', res);
  //     },
  //   },
  // );

  const { run, loading } = useRequest(
    () => {
      return deposit(address!, value);
    },
    {
      manual: true,
      onSuccess() {
        message.success('Deposit success');
        onUpdate?.();
        refreshBalance();
      },
      onError() {
        message.error('Deposit failed');
      },
    },
  );

  function handleSummit() {
    if (parseUnits(value).gt(balance!)) {
      message.error(t('Insufficient balance', 'Insufficient balance'));

      return;
    }

    run();
  }

  const receiveCusdt = useMemo(() => {
    try {
      const n = (+value / +formatUnits(cUsdtPrice!)).toFixed(2);

      if (isNumber(+n) && !isNaN(+n)) {
        return n;
      }

      return '';
    } catch (err) {
      return '';
    }
  }, [value, cUsdtPrice]);

  const { data: pauseDepositStatus } = useRequest(
    () => {
      return pauseDeposit();
    },
    {
      ready: !!provider,
    },
  );

  return (
    <div>
      <div className="max-w-[424px] mx-auto">
        <div className="text-[#E9731E] bg-[#E9731E33] rounded-md p-[13px] text-center mb-[10px] text-[12px]">
          <Trans i18nKey={'request_detail'}>
            Please be aware, you can&apos;t immediately withdraw your assets. Withdrawals are subject to T+7
            restrictions, Withdraw follow an epoch system describe on the Withdraw panel
          </Trans>
        </div>
        <ConfigProvider
          theme={{
            components: {
              Input: {
                colorBgContainer: '#1B153A',
                colorBorder: '#1B153A',
                colorText: '#fff',
              },
            },
            token: {
              colorPrimary: '#7F68E2',
              controlHeight: 50,
            },
          }}
        >
          <Input
            value={value}
            disabled={loading || pauseDepositStatus}
            placeholder={t('Enter the amount', 'Enter the amount')}
            onChange={(e) => {
              setValue(e.target.value);
            }}
            onBlur={(e) => {
              setValue(e.target.value.replace(/[^\d.]/g, ''));
            }}
            suffix="USDT"
          ></Input>
        </ConfigProvider>

        <div className="flex justify-between items-center my-[14px]">
          <div className="flex justify-start items-start flex-col">
            {receiveCusdt && (
              <>
                <div className="text-[#777E90] text-[12px]">Your receive</div>
                <div className="text-[20px]">≈{receiveCusdt} cUSDT</div>
              </>
            )}
          </div>

          <div className="text-[#777E90] ">
            {t('Balance', 'Balance')} <EthersValue value={balance}></EthersValue>
          </div>
        </div>

        <ConnectButton>
          <ConfigProvider
            theme={{
              components: {
                Button: {
                  colorBgContainerDisabled: '#626262',
                  borderColorDisabled: '#626262',
                },
              },
              token: {
                colorPrimary: '#7F68E2',
                controlHeight: 50,
              },
            }}
          >
            <ERC20ApproveButton
              allowance={`${value}`}
              tokenAddress={process.env.REACT_APP_USDT_CONTRACT_ADDRESS}
              spenderAddress={process.env.REACT_APP_VAULT_CONTRACT_ADDRESS}
            >
              <Button
                disabled={!+value || pauseDepositStatus}
                type="primary"
                block
                className="mt-[10px]"
                loading={loading}
                onClick={() => {
                  handleSummit();
                }}
              >
                {t('Submit', 'Submit')}
              </Button>
            </ERC20ApproveButton>
          </ConfigProvider>
        </ConnectButton>
      </div>
    </div>
  );
}

function Withdraw({ isCurrent, onUpdate }: { isCurrent: boolean; onUpdate: () => void }) {
  const [value, setValue] = useState('');

  const { provider, address } = useWallet();

  const {
    epochInfo,
    balanceOf,
    totalSharesBeingWithdrawn,
    makeWithdrawRequest,
    withdraw,
    withdrawRequests,
    shareToAssetsPrice,
    pauseRedeem,
    // withdrawEpochsTimelock,
  } = useVault();

  const { data, refresh: refreshData } = useRequest(
    async () => {
      return {
        totalSharesBeingWithdrawn: await totalSharesBeingWithdrawn(address!),
        balance: await balanceOf(address!),
        epochInfo: await epochInfo(),
      };
    },
    {
      ready: !!provider,
      refreshDeps: [isCurrent],
      pollingInterval: 1000 * 10,
      onSuccess(res) {
        console.log(res);
      },
    },
  );

  // const { data: balance, refresh: refreshBalance } = useRequest(
  //   async () => {
  //     return balanceOf(address!);
  //   },
  //   {
  //     ready: !!provider,
  //     refreshDeps: [isCurrent],
  //   },
  // );

  const { run: runRequest, loading } = useRequest(
    async () => {
      return makeWithdrawRequest(address!, value);
    },
    {
      manual: true,
      onSuccess() {
        refreshData();
        refreshWithdrawRequestsData();
        message.success(t('Withdraw request success', 'Withdraw request success'));
      },
      onError(err) {
        console.log('withdraw request error', err);
        message.error(t('Withdraw request failed', 'Withdraw request failed'));
      },
    },
  );

  const { run: withdrawAction, loading: withdrawLoading } = useRequest(
    async () => {
      return withdraw(address!, withdrawRequestsData!);
    },
    {
      manual: true,
      onSuccess() {
        onUpdate?.();
        refreshData();
        message.success(t('Withdraw success', 'Withdraw success'));
      },
      onError(err) {
        console.log('withdraw error', err);

        message.error(t('Withdraw failed', 'Withdraw failed'));
      },
    },
  );

  const {
    data: withdrawRequestsData,
    loading: withdrawRequestsDataLoading,
    refresh: refreshWithdrawRequestsData,
  } = useRequest(
    async () => {
      return withdrawRequests(data?.epochInfo.currentEpoch, address!);
    },
    {
      ready: !!provider && !!data,
      pollingInterval: 1000 * 10,
      refreshDeps: [isCurrent],
      onSuccess(res) {
        console.log('withdrawRequestsData---->', res);
      },
      onError(err) {
        console.log(err);
      },
    },
  );

  function handleSummit() {
    if (parseUnits(value).gt(data!.balance)) {
      message.error(t('Insufficient balance', 'Insufficient balance'));

      return;
    }

    runRequest();
  }

  function handleWithdrawAction() {
    withdrawAction();
  }

  const { data: cUsdtPrice } = useRequest(
    async () => {
      return shareToAssetsPrice();
    },
    {
      ready: !!provider,
      refreshDeps: [isCurrent],
    },
  );

  const receiveUsdt = useMemo(() => {
    try {
      const n = (+value * +formatUnits(cUsdtPrice!)).toFixed(2);

      if (isNumber(+n) && !isNaN(+n)) {
        return n;
      }

      return '';
    } catch (err) {
      return '';
    }
  }, [value, cUsdtPrice]);

  const canRequest = useMemo(() => {
    try {
      return data!.balance.sub(data!.totalSharesBeingWithdrawn).gt(0);
    } catch {
      return false;
    }
  }, [data]);

  const { data: historyList, loading: historyLoading } = useRequest(
    async () => {
      return getWithdrawHistory({ cUSDTAddr: process.env.REACT_APP_VAULT_CONTRACT_ADDRESS!, addr: address! });
    },
    {
      ready: !!address,
      refreshDeps: [isCurrent],
    },
  );

  const filteredHistoryList = useMemo(() => {
    return historyList?.data.filter((item) => {
      try {
        return item.estimated_epoch >= data!.epochInfo.currentEpoch.toNumber();
      } catch {
        return false;
      }
    });
  }, [historyList, data]);

  const { data: pauseRedeemStatus } = useRequest(
    () => {
      return pauseRedeem();
    },
    {
      ready: !!provider,
    },
  );

  return (
    <div>
      <div className="max-w-[424px] mx-auto ">
        <div className="text-[#E9731E] bg-[#E9731E33] rounded-md p-[13px] text-center mb-[10px] text-[12px]">
          <Trans i18nKey={'withdraw_detail'}>
            You can initiate a withdrawal request at any time, but you must wait 7 days before you can officially claim
            it to your wallet. Depending on the duration of each epoch, you may need to go through a varying number of
            epochs. The system will calculate the specific epoch during which you can claim.
            <p className="text-[#F21C8F] mt-[10px] font-bold">
              It&apos;s important to note that if you miss the epoch for claiming, you will need to re-initiate the
              request.
            </p>
          </Trans>
        </div>

        <ConfigProvider
          theme={{
            components: {
              Input: {
                colorBgContainer: '#1B153A',
                colorBorder: '#1B153A',
                colorText: '#fff',
              },
            },
            token: {
              colorPrimary: '#7F68E2',
              controlHeight: 50,
            },
          }}
        >
          <Input
            value={value}
            disabled={loading || pauseRedeemStatus}
            placeholder={t('Enter the amount', 'Enter the amount')}
            onChange={(e) => {
              setValue(e.target.value);
            }}
            onBlur={(e) => {
              setValue(e.target.value.replace(/[^\d.]/g, ''));
            }}
            suffix="cUSDT"
          ></Input>
        </ConfigProvider>

        <div className="flex justify-start items-start flex-col my-[14px]">
          {receiveUsdt && (
            <>
              <div className="text-[#777E90] text-[12px]">Your receive</div>
              <div className="text-[20px]">≈{receiveUsdt} USDT</div>
            </>
          )}
        </div>

        <ConnectButton>
          <ConfigProvider
            theme={{
              components: {
                Button: {
                  colorBgContainerDisabled: '#626262',
                  borderColorDisabled: '#626262',
                },
              },
              token: {
                colorPrimary: '#7F68E2',

                controlHeight: 50,
              },
            }}
          >
            <Button
              disabled={!+value || !canRequest || pauseRedeemStatus}
              type="primary"
              block
              className="mt-[10px]"
              loading={loading}
              onClick={() => {
                handleSummit();
              }}
            >
              {t('Request', 'Request')}
            </Button>

            <Button
              disabled={withdrawRequestsData?.isZero?.() || pauseRedeemStatus}
              type="primary"
              block
              className="mt-[10px]"
              loading={withdrawLoading || withdrawRequestsDataLoading}
              ghost
              onClick={() => {
                handleWithdrawAction();
              }}
            >
              {t('Claim', 'Claim')}
            </Button>
          </ConfigProvider>
        </ConnectButton>

        <div className="text-[#777E90] mt-[20px]">
          {t('Existing Requests', 'Existing Requests')}
          <EthersValue value={data?.totalSharesBeingWithdrawn} placeholder="0"></EthersValue>
        </div>

        <div className="bg-[#1B153A] rounded p-[12px] pb-0">
          <div className=" flex space-between items-center w-full text-[12px]">
            <div className="text-center w-1/3">{t('Amount', 'Amount')}</div>
            <div className="text-center w-1/3">{t('Estimated Epoch', 'Estimated Epoch')}</div>
            <div className="text-center w-1/3">{t('Withdraw', 'Withdraw')}</div>
          </div>

          <List
            loading={historyLoading}
            dataSource={filteredHistoryList}
            renderItem={(item) => (
              <List.Item>
                <div className=" flex space-between items-center w-full">
                  <div className="text-center w-1/3">{item.amount}</div>
                  <div className="text-center w-1/3">{item.estimated_epoch}</div>
                  <div className="text-center w-1/3">{item.withdraw_date}</div>
                </div>
              </List.Item>
            )}
          />
        </div>
      </div>
    </div>
  );
}

export default function () {
  const [key, setKey] = useState('1');
  const [calculatedTvl, setCalculatedTvl] = useState('0');

  const items: TabsProps['items'] = [
    {
      key: '1',
      label: t('Deposit', 'Deposit'),
      children: (
        <Stake
          isCurrent={key === '1'}
          onUpdate={() => {
            refreshData();
            refreshSats();
          }}
        />
      ),
    },
    {
      key: '2',
      label: t('Withdraw', 'Withdraw'),
      children: (
        <Withdraw
          isCurrent={key === '2'}
          onUpdate={() => {
            refreshData();
            refreshSats();
          }}
        />
      ),
    },
  ];

  const { provider, address } = useWallet();

  const { tvl, collateralizationP, shareToAssetsPrice, totalSupply, balanceOf, epochInfo, assetsDeposits } = useVault();

  const { data, refresh: refreshData } = useRequest(
    async () => {
      return {
        tvl: await tvl(),
        collateralizationP: await collateralizationP(),
        shareToAssetsPrice: await shareToAssetsPrice(),
        totalSupply: await totalSupply(),
      };
    },
    {
      ready: !!provider,
      pollingInterval: 1000 * 10,
    },
  );

  useEffect(() => {
    if (data?.shareToAssetsPrice && data?.totalSupply) {
      const decimals = 18; // 这是 BigNumber 中表示十进制的基数，ETH 通常是 18

      // 将 BigNumber 格式化为具有 18 位小数的字符串，然后转换为数字进行四舍五入
      const price = parseFloat(ethers.utils.formatUnits(data.shareToAssetsPrice, decimals));
      const supply = parseFloat(ethers.utils.formatUnits(data.totalSupply, decimals));

      // 四舍五入到两位小数
      const roundedPrice = Math.round(price * 100) / 100;
      const roundedSupply = Math.round(supply * 100) / 100;

      const calcTvl = (roundedPrice * roundedSupply).toFixed(2);

      setCalculatedTvl(calcTvl);
    }
  }, [data?.shareToAssetsPrice, data?.totalSupply]);

  const { data: sats, refresh: refreshSats } = useRequest(
    async () => {
      const balance = await balanceOf(address!);

      return {
        available: balance,
        locked: await assetsDeposits(address!),
        // earnings: await totalSupply(),
      };
    },
    {
      ready: !!provider && !!data,
    },
  );

  const totalValue = useMemo(() => {
    try {
      return (+formatUnits(sats!.available) * +formatUnits(data!.shareToAssetsPrice)).toFixed(2);
    } catch (err) {
      console.log(err);

      return '';
    }
  }, [data, sats]);

  const { data: epochInfoData } = useRequest(
    async () => {
      return epochInfo();
    },
    {
      ready: !!provider,
      pollingInterval: 1000 * 10,
    },
  );

  const deadline = useMemo(() => {
    try {
      return epochInfoData!.currentEpochStart.add(epochInfoData!.ePochTimeDuration).toNumber() * 1000;
    } catch (err) {
      return '';
    }
  }, [epochInfoData]);

  // const deadline = useMemo(() => {
  //   try {
  //     return sats?.available.mul(+formatUnits(data!.shareToAssetsPrice));
  //   } catch (err) {
  //     return '';
  //   }
  // },[sts])

  const earnings = useMemo(() => {
    try {
      return (+totalValue - +formatUnits(sats!.locked)).toFixed(4);
    } catch (err) {
      return '';
    }
  }, [sats, totalValue]);

  return (
    <div
      className="w-screen h-screen text-white pb-[30px] overflow-y-auto"
      style={{
        // backgroundImage: 'url(/static/images/default-background.png)',
        background: '#0F0B23',
        backgroundSize: '100% 100%',
      }}
    >
      <Header></Header>

      <div className="max-w-[640px] mx-auto md:px-[15px]">
        <div className="flex justify-between items-center my-[26px]">
          <div className="text-xl">{t('Vault', 'Vault')}</div>
          <div className="text-base flex justify-end items-center gap-x-[10px]">
            <span className="mr-2">Epoch {epochInfoData?.currentEpoch?.toNumber?.()}</span>
            <span className="text-primary flex justify-end items-center">
              Remaining:
              <div className="ml-1">
                {deadline ? (
                  <Countdown
                    value={deadline}
                    valueStyle={{
                      fontSize: '14px',
                      display: 'inline-block',
                    }}
                  />
                ) : (
                  <span>-</span>
                )}
              </div>
            </span>
          </div>
        </div>
        <div className=" px-5 py-[10px] rounded border border-solid border-[#241C4C] flex justify-between items-start">
          <div className="flex-col justify-start items-center gap-2.5 inline-flex">
            <div className="py-1 justify-start items-center gap-2.5 inline-flex">
              <div className="opacity-70 text-white text-[10px] font-normal font-['Prompt'] leading-[10px]">
                {t('TVL', 'TVL')}
              </div>
            </div>
            <div className="justify-center items-center gap-1 inline-flex">
              <div className="text-[#06B470] text-lg font-normal font-['Prompt']">
                {/* $<EthersValue value={calculatedTvl}></EthersValue> */}${calculatedTvl}
              </div>
            </div>
          </div>

          <div className="flex-col justify-start items-center gap-2.5 inline-flex">
            <div className="py-1 justify-start items-center gap-2.5 inline-flex">
              <div className="opacity-70 text-white text-[10px] font-normal font-['Prompt'] leading-[10px]">
                {t('Collat Ratio', 'Collat Ratio')}
              </div>
            </div>
            <div className="justify-center items-center gap-1 inline-flex">
              <div className="text-white text-lg font-normal font-['Prompt']">
                <EthersValue value={data?.collateralizationP}></EthersValue>%
              </div>
            </div>
          </div>

          <div className="flex-col justify-start items-center gap-2.5 inline-flex">
            <div className="py-1 justify-start items-center gap-2.5 inline-flex">
              <div className="opacity-70 text-white text-[10px] font-normal font-['Prompt'] leading-[10px]">
                {t('cUSDT Price', 'cUSDT Price')}
              </div>
            </div>
            <div className="justify-center items-center gap-1 inline-flex">
              <div className="text-[#06B470] text-lg font-normal font-['Prompt']">
                ${<EthersValue value={data?.shareToAssetsPrice}></EthersValue>}
              </div>
            </div>
          </div>

          <div className="flex-col justify-start items-center gap-2.5 inline-flex">
            <div className="py-1 justify-start items-center gap-2.5 inline-flex">
              <div className="opacity-70 text-white text-[10px] font-normal font-['Prompt'] leading-[10px]">
                {t('cUSDT Supply', 'cUSDT Supply')}
              </div>
            </div>
            <div className="justify-center items-center gap-1 inline-flex">
              <div className="text-white text-lg font-normal font-['Prompt']">
                {' '}
                <EthersValue value={data?.totalSupply}></EthersValue>
              </div>
            </div>
          </div>
        </div>

        <div className="text-center w-full font-bold opacity-70 my-[26px]">{t('Your Stats', 'Your Stats')}</div>

        <div className=" px-5 py-[10px] rounded border border-solid border-[#241C4C] flex justify-between items-start">
          <div className="flex-col justify-start items-center gap-2.5 inline-flex">
            <div className="py-1 justify-start items-center gap-2.5 inline-flex">
              <div className="opacity-70 text-white text-[10px] font-normal font-['Prompt'] leading-[10px]">
                {t('Available', 'Available')}
              </div>
            </div>
            <div className="justify-center items-center gap-1 inline-flex">
              <div className="text-lg font-normal font-['Prompt']">
                $<EthersValue value={sats?.available} placeholder="0"></EthersValue>
              </div>
            </div>
          </div>

          {/* <div className="flex-col justify-start items-center gap-2.5 inline-flex">
            <div className="py-1 justify-start items-center gap-2.5 inline-flex">
              <div className="opacity-70 text-white text-[10px] font-normal font-['Prompt'] leading-[10px]">
                {t('Locked', 'Locked')}
              </div>
            </div>
            <div className="justify-center items-center gap-1 inline-flex">
              <div className="text-white text-lg font-normal font-['Prompt']">
                <EthersValue value={sats?.locked}></EthersValue>
              </div>
            </div>
          </div> */}

          <div className="flex-col justify-start items-center gap-2.5 inline-flex">
            <div className="py-1 justify-start items-center gap-2.5 inline-flex">
              <div className="opacity-70 text-white text-[10px] font-normal font-['Prompt'] leading-[10px]">
                {t('Total Value', 'Total Value')}
              </div>
            </div>
            <div className="justify-center items-center gap-1 inline-flex">
              <div className="text-lg font-normal font-['Prompt']">${totalValue}</div>
            </div>
          </div>

          <div className="flex-col justify-start items-center gap-2.5 inline-flex">
            <div className="py-1 justify-start items-center gap-2.5 inline-flex">
              <div className="opacity-70 text-white text-[10px] font-normal font-['Prompt'] leading-[10px]">
                {t('Est.Earnings', 'Est.Earnings')}
              </div>
            </div>
            <div className="justify-center items-center gap-1 inline-flex">
              <div className="text-white text-lg font-normal font-['Prompt']">${earnings || '0'}</div>
            </div>
          </div>
        </div>

        <div className="mt-[20px]">
          <Tabs
            className="w-full"
            centered
            defaultActiveKey={key}
            items={items}
            onChange={(key) => {
              setKey(key);
            }}
          />
        </div>
      </div>
    </div>
  );
}
