import { ConfigProvider, Modal, ModalProps } from 'antd';
import classNames from 'classnames';

export function AppModal(props: ModalProps) {
  return (
    <ConfigProvider
      theme={{
        components: {
          Modal: {
            footerBg: '#241C4C',
            contentBg: '#241C4C',
            headerBg: '#241C4C',
          },
        },
      }}
    >
      <Modal {...props} width={340} centered></Modal>
    </ConfigProvider>
  );
}

export function Field({ title, value, className }: { title: any; value: any; className?: string }) {
  return (
    <div className={classNames('text-[14px]', className)}>
      <div className="text-[#777E90] text-center">{title}</div>
      <div className="text-white text-center">{value}</div>
    </div>
  );
}
