import { IdoV2__factory } from '@/common/contracts/typechain';
import { useWallet } from '@/common/hooks/wagmi/useWallet';

export function useContract() {
  const { provider } = useWallet();

  function getContract() {
    return IdoV2__factory.connect(process.env.REACT_APP_IDO_V2_CONTRACT_ADDRESS!, provider);
  }

  async function buy(packageAmount: number) {
    const tx = await getContract().buy(packageAmount);

    const res = await tx.wait();

    return res;
  }

  async function claim() {
    const tx = await getContract().claim();

    const res = await tx.wait();

    return res;
  }

  async function idoInfo() {
    const [startDate, endDate, idoTokenName, amountTokenPerPack, pricePerPack, paymentToken] =
      await getContract().idoInfo();

    return {
      startDate,
      endDate,
      idoTokenName,
      amountTokenPerPack,
      pricePerPack,
      paymentToken,
    };
  }

  async function userIdoInfo(address: string) {
    const [buyPackageNum, reward, received] = await getContract().users(address);

    return {
      buyPackageNum,
      reward,
      received,
    };
  }

  async function whitelist(address: string) {
    return getContract().whitelist(address);
  }

  return {
    buy,
    claim,
    idoInfo,
    userIdoInfo,
    whitelist,
  };
}
