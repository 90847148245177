import { ConnectButton } from '@/components/App/ConnectButton';
import Header from '@/components/UI/Header';
import { Button, ConfigProvider, Modal, ModalProps, Radio, message } from 'antd';
import Countdown from 'antd/es/statistic/Countdown';
// import { t } from 'i18next';
import { Trans, useTranslation } from 'react-i18next';
import { useContract } from './useContract';
import { useWallet } from '@/common/hooks/wagmi/useWallet';
import { useRequest } from 'ahooks';
import { useMemo, useState } from 'react';
import dayjs from 'dayjs';
import { BigNumber, ethers } from 'ethers';
import { EthersValue } from '@/components/App/EthersValue';
import { useERC20Contract } from '@/common/contracts/hooks/useERC20Contract';
import ERC20ApproveButton from '@/components/App/ERC20ApproveButton';
import { IdoV2__factory } from '@/common/contracts/typechain';
import { bsc } from '@wagmi/core/chains';

function _formatTime(time: number) {
  return dayjs(time).format('YYYY.MM.DD HH:mm');
}

function AppModal(props: ModalProps) {
  return (
    <ConfigProvider
      theme={{
        components: {
          Modal: {
            footerBg: '#241C4C',
            contentBg: '#241C4C',
            headerBg: '#241C4C',
          },
        },
      }}
    >
      <Modal {...props} width={340} centered></Modal>
    </ConfigProvider>
  );
}

async function getIODInfo() {
  const contract = IdoV2__factory.connect(
    process.env.REACT_APP_IDO_V2_CONTRACT_ADDRESS!,
    new ethers.providers.JsonRpcProvider(bsc.rpcUrls.default.http[0]),
  );

  const [startDate, endDate, idoTokenName, amountTokenPerPack, pricePerPack, paymentToken] = await contract.idoInfo();

  return {
    startDate,
    endDate,
    idoTokenName,
    amountTokenPerPack,
    pricePerPack,
    paymentToken,
  };
}

// const mockTime = Date.now() + 1000 * 60 * 60 * 24 * 2 + 1000 * 30;

function BuyModal({
  open,
  onClose,
  onSuccess,
  pricePerPack,
  buyPackageNum,
}: {
  open: boolean;
  onClose: () => void;
  onSuccess: () => void;
  pricePerPack?: BigNumber;
  buyPackageNum?: BigNumber;
}) {
  const { address, provider } = useWallet();

  const { buy } = useContract();

  const [value, setValue] = useState(1);

  const { t } = useTranslation();

  const buyPackageNumber = useMemo(() => {
    return buyPackageNum?.toNumber() || 0;
  }, [buyPackageNum]);

  const { loading, run } = useRequest(
    async () => {
      return buy(value);
    },
    {
      manual: true,
      ready: !!provider,
      onSuccess() {
        onSuccess?.();
        message.success(t('Buy success', 'Buy success'));
      },
      onError(err) {
        console.log(err);

        message.error(t('Buy failed', 'Buy failed'));
      },
    },
  );

  const { balanceOf } = useERC20Contract();

  const { data: balance } = useRequest(
    () => {
      return balanceOf(process.env.REACT_APP_USDT_CONTRACT_ADDRESS!);
    },
    {
      ready: !!provider,
    },
  );

  function handleBuy() {
    console.log(pricePerPack!.mul(value).gt(balance!));

    if (pricePerPack!.mul(value).gt(balance!)) {
      message.error(t('Insufficient balance', 'Insufficient balance'));

      return;
    }

    run();
  }

  return (
    <AppModal
      title={t('IDO Participate', 'IDO Participate')}
      open={open}
      onCancel={onClose}
      footer={
        <ConfigProvider
          theme={{
            components: {
              Button: {
                colorBgContainerDisabled: '#626262',
                borderColorDisabled: '#626262',
              },
            },
            token: {
              colorPrimary: '#0AAE55',
              controlHeight: 50,
            },
          }}
        >
          <ERC20ApproveButton
            allowance={`1440`}
            tokenAddress={process.env.REACT_APP_USDT_CONTRACT_ADDRESS}
            spenderAddress={process.env.REACT_APP_IDO_V2_CONTRACT_ADDRESS}
          >
            <Button
              type="primary"
              block
              loading={loading}
              disabled={value > 3 - buyPackageNumber}
              onClick={() => {
                handleBuy();
              }}
            >
              {t('Buy', 'Buy')}
            </Button>
          </ERC20ApproveButton>
          <div className="flex justify-end items-center my-[10px]">
            <div>
              <span className="text-[#777E90]">{t('Balance', 'Balance')}:</span>{' '}
              <EthersValue value={balance}></EthersValue>
            </div>
          </div>
        </ConfigProvider>
      }
    >
      <div className="my-[10px] ">{t('Ticket Amount', 'Ticket Amount')}</div>
      <div className="w-full flex justify-center items-center">
        <Radio.Group
          className="w-full"
          options={[
            {
              label: <div className="w-[65px] text-center">1</div>,
              value: 1,
              disabled: buyPackageNumber >= 3,
            },
            {
              label: <div className="w-[68px] text-center">2</div>,
              value: 2,
              disabled: buyPackageNumber >= 2,
            },
            {
              label: <div className="w-[65px] text-center">3</div>,
              value: 3,
              disabled: buyPackageNumber >= 1,
            },
          ]}
          onChange={(e) => {
            setValue(e.target.value);
          }}
          value={value}
          optionType="button"
          buttonStyle="solid"
        />
      </div>
    </AppModal>
  );
}

export default function () {
  const { provider, address } = useWallet();

  const { idoInfo, userIdoInfo, claim, whitelist } = useContract();

  const { data } = useRequest(
    () => {
      return provider ? idoInfo() : getIODInfo();
    },
    {
      refreshDeps: [!!provider],
      onSuccess(res) {
        console.log('idoInfo', res);
      },
    },
  );

  const startTime = useMemo(() => {
    try {
      // return mockTime;

      const val = data!.startDate.toNumber() * 1000;

      if (val <= +Date.now()) return 0;

      return val;
    } catch (err) {
      return 0;
    }
  }, [data]);

  const endTime = useMemo(() => {
    try {
      const val = data!.endDate.toNumber() * 1000;

      if (val <= +Date.now()) return 0;

      return val;
    } catch (err) {
      return 0;
    }
  }, [data]);

  function formatTime(time?: BigNumber) {
    try {
      const val = time!.toNumber() * 1000;

      return _formatTime(val);
    } catch (err) {
      return '-';
    }
  }

  const [open, setOpen] = useState(false);

  const { t } = useTranslation();

  const { data: userIdo, refresh: refreshUserIdo } = useRequest(
    () => {
      return userIdoInfo(address!);
    },
    {
      ready: !!provider && !!address,
    },
  );

  const canClaim = useMemo(() => {
    try {
      return userIdo?.reward.gt(userIdo.received) && !endTime;
    } catch {
      return false;
    }
  }, [endTime, userIdo]);

  const { loading: claimLoading, run: runClaim } = useRequest(
    async () => {
      return claim();
    },
    {
      manual: true,
      ready: !!provider,
      onSuccess() {
        refreshUserIdo();
        message.success(t('Claim success', 'Claim success'));
      },
      onError(err) {
        console.log(err);

        message.error(t('Claim failed', 'Claim failed'));
      },
    },
  );

  const { data: eligible, loading: eligibleLoading } = useRequest(
    async () => {
      return whitelist(address!);
    },
    {
      ready: !!provider && !!address,
    },
  );

  const reward = useMemo(() => {
    try {
      return userIdo?.reward.sub(userIdo.received);
    } catch {
      return 0;
    }
  }, [userIdo]);

  const { data: startedTime } = useRequest(
    async () => {
      try {
        // return mockTime;

        const val = data!.startDate.toNumber() * 1000;

        const currentTime = dayjs(); // 当前时间

        const diff = currentTime.diff(val); // 两个时间的差异，单位为毫秒

        const days = Math.floor(diff / (24 * 60 * 60 * 1000)); // 天数

        const hours = Math.floor((diff % (24 * 60 * 60 * 1000)) / (60 * 60 * 1000)); // 小时

        const minutes = Math.floor((diff % (60 * 60 * 1000)) / (60 * 1000)); // 分钟

        const seconds = Math.floor((diff % (60 * 1000)) / 1000); // 秒数

        // 格式化输出
        const formatted = `${days}d ${hours}h ${minutes}m ${seconds}s`;

        return formatted;
      } catch (err) {
        console.log(err);

        return 0;
      }
    },
    {
      ready: !!data?.startDate,
      pollingInterval: 1000,
    },
  );

  return (
    <div
      className="w-screen h-screen pb-[30px] overflow-y-auto"
      style={{
        // backgroundImage: 'url(/static/images/default-background.png)',
        background: '#0F0B23',
        backgroundSize: '100% 100%',
      }}
    >
      <Header></Header>

      <div className="w-[410px] mx-auto text-white text-xl mt-[30px] md:w-full md:px-[10px]">
        <div
          className="w-full min-h-[148px] flex flex-col justify-between relative"
          style={{
            backgroundImage: 'url(/static/images/ido-bg.png)',
            backgroundSize: '100% 100%',
          }}
        >
          <div>{t('Initial Digital Assets Offering', 'Initial Digital Assets Offering')}</div>

          <div className="text-[36px] pb-[40px] pl-[40px] flex justify-start items-center gap-x-[10px]">
            <img src="/static/images/icon/eCat.png" className="w-[50px]" />
            eCat
          </div>

          <div
            className="absolute right-[10px] top-[50px]  rounded-full px-[10px py-[2px] text-[12px] min-w-[79px] text-center"
            style={{
              background: {
                0: '#0AAF56',
                1: '#0AAF56',
                2: '#626262',
              }[!startTime ? (endTime ? 1 : 2) : 0],
            }}
          >
            {
              {
                0: t('Upcoming', 'Upcoming'),
                1: t('Live', 'Live'),
                2: t('End', 'End'),
              }[!startTime ? (endTime ? 1 : 2) : 0]
            }
          </div>
        </div>

        {/* {(!!startTime || !!startedTime) && (
          <div className="flex flex-col justify-center items-center my-[30px]">
            {
              startTime ? (
                <>
                  <div className="opacity-75 text-[14px] text-center">{t('Starts in', 'Starts in')}</div>

                  <div>
                    <Countdown
                      value={startTime}
                      format="D [d] H [h] m [m] s [s]"
                      onFinish={() => {
                        window.location.reload();
                      }}
                      valueStyle={{
                        fontSize: '54px',
                        color: 'white',
                        fontWeight: 400,
                      }}
                    />
                  </div>
                </>
              ) : (
                <>
                  {t('Started', 'Started')}
                  <div
                    style={{
                      fontSize: '54px',
                      color: 'white',
                      fontWeight: 400,
                      marginTop: '26px',
                      marginBottom: '31px',
                    }}
                  >
                    {startedTime}
                  </div>
                </>
              )
              // (
              //   <>
              //     <div className="opacity-75 text-[14px] text-center">{t('Ends in', 'Ends in')}</div>

              //     <div>
              //       <Countdown
              //         value={endTime}
              //         format="D [d] H [h] m [m] s [s]"
              //         onFinish={() => {
              //           window.location.reload();
              //         }}
              //         valueStyle={{
              //           fontSize: '54px',
              //           color: 'white',
              //           fontWeight: 400,
              //         }}
              //       />
              //     </div>
              //   </>
              // )
            }
          </div>
        )} */}

        <div className="flex justify-between items-start gap-[5px] ">
          <div className="w-full px-5 py-[5px] rounded border border-solid border-[#241C4C] flex-col justify-start items-center gap-2.5 inline-flex">
            <div className="py-1 justify-start items-center gap-2.5 inline-flex">
              <div className="opacity-70 text-white text-[10px] font-normal font-['Prompt'] leading-[10px]">
                {t('Start Date', 'Start Date')}
              </div>
            </div>
            <div className="justify-center items-center gap-1 inline-flex">
              <div className="text-white text-lg font-normal font-['Prompt']">{formatTime(data?.startDate)}</div>
            </div>
          </div>

          {/* <div className="w-1/2 px-5 py-[5px] rounded border border-solid border-[#241C4C] flex-col justify-start items-center gap-2.5 inline-flex">
            <div className="py-1 justify-start items-center gap-2.5 inline-flex">
              <div className="opacity-70 text-white text-[10px] font-normal font-['Prompt'] leading-[10px]">
                {t('Ends Date', 'Ends Date')}
              </div>
            </div>
            <div className="justify-center items-center gap-1 inline-flex">
              <div className="text-white text-lg font-normal font-['Prompt']">{formatTime(data?.endDate)}</div>
            </div>
          </div> */}
        </div>

        <div className="flex justify-between items-start gap-[5px] mt-[5px]">
          <div className="w-1/3 px-5 py-[5px] rounded border border-solid border-[#241C4C] flex-col justify-start items-center gap-2.5 inline-flex">
            <div className="py-1 justify-start items-center gap-2.5 inline-flex">
              <div className="opacity-70 text-white text-[10px] font-normal font-['Prompt'] leading-[10px]">
                {t('Token', 'Token')}
              </div>
            </div>
            <div className="justify-center items-center gap-1 inline-flex">
              <div className="text-white text-lg font-normal font-['Prompt']">{data?.idoTokenName || '-'}</div>
            </div>
          </div>

          <div className="w-1/3 px-5 py-[5px] rounded border border-solid border-[#241C4C] flex-col justify-start items-center gap-2.5 inline-flex">
            <div className="py-1 justify-start items-center gap-2.5 inline-flex">
              <div className="opacity-70 text-white text-[10px] font-normal font-['Prompt'] leading-[10px]">
                {t('Ticket amount', 'Ticket amount')}
              </div>
            </div>
            <div className="justify-center items-center gap-1 inline-flex">
              <div className="text-white text-lg font-normal font-['Prompt']">
                <EthersValue value={data?.amountTokenPerPack}></EthersValue>
              </div>
            </div>
          </div>

          <div className="w-1/3 px-5 py-[5px] rounded border border-solid border-[#241C4C] flex-col justify-start items-center gap-2.5 inline-flex">
            <div className="py-1 justify-start items-center gap-2.5 inline-flex">
              <div className="opacity-70 text-white text-[10px] font-normal font-['Prompt'] leading-[10px]">
                {t('Ticket Price', 'Ticket Price')}
              </div>
            </div>
            <div className="justify-center items-center gap-1 inline-flex">
              <div className="text-white text-lg font-normal font-['Prompt']">
                <EthersValue value={data?.pricePerPack}></EthersValue>
              </div>
            </div>
          </div>
        </div>

        <div className="my-[20px]">
          <ConnectButton>
            <ConfigProvider
              theme={{
                components: {
                  Button: {
                    colorBgContainerDisabled: '#626262',
                    borderColorDisabled: '#626262',
                  },
                },
                token: {
                  colorPrimary: '#0AAE55',
                },
              }}
            >
              <Button
                type="primary"
                disabled={!!startTime || !endTime || !eligible}
                block
                onClick={() => {
                  setOpen(true);
                }}
              >
                {t('Buy', 'Buy')}
              </Button>
            </ConfigProvider>
          </ConnectButton>

          {!eligible && !eligibleLoading && !!address && (
            <div className="my-[15px] text-[red] text-center">{t('You are not eligible', 'You are not eligible')}</div>
          )}
        </div>

        <div className="text-[12px] text-opacity-70  rounded border border-solid border-[#241C4C] p-[20px]">
          {eligible && (
            <div className="text-center mb-[30px]">
              <div className="text-[#777E90]">{t('To be claimed', 'To be claimed')}</div>

              <div className="text-[18px] my-[10px]">
                <EthersValue value={reward}></EthersValue> eCAT
              </div>
              <ConnectButton>
                <ConfigProvider
                  theme={{
                    components: {
                      Button: {
                        colorBgContainerDisabled: '#626262',
                        borderColorDisabled: '#626262',
                      },
                    },
                    token: {
                      colorPrimary: '#8069E3',
                    },
                  }}
                >
                  <Button
                    type="primary"
                    disabled={!canClaim}
                    loading={claimLoading}
                    block
                    ghost
                    onClick={() => {
                      runClaim();
                    }}
                  >
                    {t('Claim', 'Claim')}
                  </Button>
                </ConfigProvider>
              </ConnectButton>
            </div>
          )}

          <div className="text-[#B6B5BC] leading-4 text-[14px]">
            <span className="text-[16px] text-white inline-block mb-[10px]">{t('Reminder', 'Reminder')}:</span>
            <br />
            {t('1.Limited time participation', '1.Limited time participation')}
            <span className="text-[#8069E3]">{formatTime(data?.startDate)}</span>
            {/* {t('to', 'to')}{' '} */}
            {/* <span className="text-[#8069E3]">{formatTime(data?.endDate)}</span>. */}
            <p className="my-[10px] break-words break-all">
              {t(
                '2. Each user can purchase up to 3 tickets during this event.',
                '2. Each user can purchase up to 3 tickets during this event.',
              )}
            </p>
            <p>
              <Trans i18nKey={'reminder-3'}>
                3. The rewards of this event will be available for <span className="text-[#8069E3]">claiming</span> on
                the end day.
              </Trans>
            </p>
            <p className="mt-[10px]">
              {t(
                '4.Copycat Dex has the final right to interpret the event',
                '4.Copycat Dex has the final right to interpret the event',
              )}
            </p>
            <br />
          </div>
        </div>
      </div>

      <BuyModal
        key={open ? 1 : 0}
        open={open}
        onSuccess={() => {
          setOpen(false);
          refreshUserIdo();
        }}
        onClose={() => {
          setOpen(false);
        }}
        pricePerPack={data?.pricePerPack}
        buyPackageNum={userIdo?.buyPackageNum}
      />
    </div>
  );
}
