import { AxiosError } from 'axios';

/**
 * error handler
 * @param err
 * @returns
 */
export const errorHandler = (err: AxiosError) => {
  const status = err?.response?.status;

  switch (status) {
    default:
      return Promise.reject(err);
  }
};
