import { useMemo } from 'react';
import { Button, message } from 'antd';
import { useERC20Contract } from '@/common/contracts/hooks/useERC20Contract';
import { useWallet } from '@/common/hooks/wagmi/useWallet';
import { useRequest } from 'ahooks';

export default function Erc20ApproveButton({
  children,
  tokenAddress,
  spenderAddress,
  disabled,
  className,
  allowance,
}: {
  children: any;
  tokenAddress?: string;
  spenderAddress?: string;
  disabled?: boolean;
  className?: string;
  allowance: string;
}) {
  const erc20 = useERC20Contract();

  const { provider } = useWallet();

  const {
    data: approved,
    loading,
    refresh: refreshApproveStatus,
  } = useRequest(
    () => {
      return erc20.hasApproved(tokenAddress!, spenderAddress!, allowance);
    },
    {
      ready: !!tokenAddress && !!provider && !!spenderAddress && !!+allowance,
      refreshDeps: [allowance],
    },
  );

  const { loading: approveLoading, refreshAsync: handleApprove } = useRequest(
    () => {
      return erc20.approve(tokenAddress!, spenderAddress!, allowance);
    },
    {
      manual: true,
      onSuccess() {
        refreshApproveStatus();
      },
      onError() {
        message.error('Approve fail');
      },
    },
  );

  const approveStatus = useMemo(() => {
    return !approved;
  }, [approved]);

  return (
    <>
      {approveStatus && allowance ? (
        <Button
          disabled={disabled}
          loading={loading || approveLoading}
          onClick={handleApprove}
          block
          type="primary"
          className={className}
        >
          Approve
        </Button>
      ) : (
        children
      )}
    </>
  );
}
