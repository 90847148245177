import { LPStaking__factory } from '@/common/contracts/typechain';
import { useWallet } from '@/common/hooks/wagmi/useWallet';
import { parseUnits } from 'ethers/lib/utils';

export function useContract() {
  const { provider } = useWallet();

  function getContract() {
    return LPStaking__factory.connect(process.env.REACT_APP_LP_STAKE_CONTRACT_ADDRESS!, provider);
  }

  // lp
  async function stake(amount: string) {
    const tx = await getContract().stake(parseUnits(amount));

    const res = await tx.wait();

    return res;
  }

  async function getStakeInfo(address: string) {
    const [staked, earnings, received] = await getContract().getStakeInfo(address);

    return {
      staked,
      earnings,
      received,
    };
  }

  function getPoolInfo() {
    return getContract().getPoolInfo();
  }

  async function unStakeLp(amount: string) {
    const tx = await getContract().unstake(parseUnits(amount));

    const res = await tx.wait();

    return res;
  }

  async function claim() {
    const tx = await getContract().claim();

    const res = await tx.wait();

    return res;
  }

  return {
    stake,
    getStakeInfo,
    getPoolInfo,
    unStakeLp,
    claim,
  };
}
