import { HashRouter } from 'react-router-dom';
import { Initialization } from '@/components/App/Initialization';
import { Theme } from '@/components/App/Theme';
import Routes from './routes';
import { useClient } from './common/hooks/wagmi/useClient';
import { WagmiConfig } from 'wagmi';
import '@/assets/css/tailwind.css';
import '@/assets/css/main.css';
import '@/assets/font/stylesheet.css';
import '@/assets/css/icon/v2/iconfont.css';
import '@/assets/css/icon/iconfont.css';

function App() {
  const { client } = useClient();

  return (
    <HashRouter>
      <WagmiConfig config={client}>
        <Initialization>
          <Theme>
            <Routes />
          </Theme>
        </Initialization>
      </WagmiConfig>
    </HashRouter>
  );
}

export default App;
