import Header from '@/components/UI/Header';
import { Button, ConfigProvider, Input, message } from 'antd';
import { useStake } from './useStake';
import { useMemo, useState } from 'react';
import { useRequest } from 'ahooks';
import { useWallet } from '@/common/hooks/wagmi/useWallet';
import { EthersValue } from '@/components/App/EthersValue';
import ERC20ApproveButton from '@/components/App/ERC20ApproveButton';
import { useERC20Contract } from '@/common/contracts/hooks/useERC20Contract';
import { claimCat, getLinearUnlocks } from '@/service';
import { ConnectButton } from '@/components/App/ConnectButton';
import { formatUnits, parseEther, parseUnits } from 'ethers/lib/utils';
import { useTranslation } from 'react-i18next';
import { BigNumber } from 'ethers';

export default function () {
  const { stake, getTotalStaked, getCostSavings, claim } = useStake();

  const { provider, address } = useWallet();

  const [value, setValue] = useState('');

  const { t } = useTranslation();

  const { refresh: refreshTotalStaked } = useRequest(
    () => {
      return getTotalStaked();
    },
    {
      ready: !!provider,
    },
  );

  const { run, loading } = useRequest(
    () => {
      return stake(value);
    },
    {
      ready: !!provider,
      manual: true,
      onSuccess() {
        refresh();
        refreshTotalStaked();
        refreshCostSavings();
        message.success(t('Stake success', 'Stake success'));
      },
      onError() {
        message.error(t('Stake failed', 'Stake failed'));
      },
    },
  );

  const { balanceOf } = useERC20Contract();

  const { data: eCatBalance, refresh } = useRequest(
    () => {
      return balanceOf(process.env.REACT_APP_ECAT_CONTRACT_ADDRESS!);
    },
    {
      ready: !!provider,
    },
  );

  const { data: costSavings, refresh: refreshCostSavings } = useRequest(
    () => {
      return getCostSavings(address!);
    },
    {
      ready: !!provider,
    },
  );

  const { data: claimInfo, refresh: refreshClaimInfoInfo } = useRequest(
    async () => {
      const res = await claimCat({ address: address! });

      return res.data;
    },
    {
      ready: !!address,
    },
  );

  const { run: runClaim, loading: claimLoading } = useRequest(
    async () => {
      const res = await claimCat({ address: address! });

      return claim(res.data);
    },
    {
      manual: true,
      onError() {
        message.error(t('Claim failed', 'Claim failed'));
      },
      onSuccess() {
        message.success(t('Claim success', 'Claim success'));
        refreshClaimInfoInfo();
      },
    },
  );

  const claimable = useMemo(() => {
    try {
      console.log('claimedCat', formatUnits(costSavings!.claimedCat));

      return BigNumber.from(claimInfo?.maxAmount).sub(costSavings!.claimedCat);
    } catch (err) {
      return BigNumber.from(0);
    }
  }, [claimInfo?.maxAmount, costSavings]);

  function handleSummit() {
    if (parseUnits(value).gt(eCatBalance!)) {
      message.error(t('Insufficient balance', 'Insufficient balance'));

      return;
    }

    run();
  }

  const canClaim = useMemo(() => {
    try {
      return claimable.gte(parseEther('0.01'));
    } catch (err) {
      return false;
    }
  }, [claimable]);

  return (
    <div
      className="w-screen h-screen overflow-y-auto pb-[30px]"
      style={{
        // backgroundImage: 'url(/static/images/default-background.png)',
        background: '#0F0B23',
        backgroundSize: '100% 100%',
      }}
    >
      <Header></Header>

      <div className="w-[410px] md:w-full mx-auto md:px-[20px]">
        <div className="text-white text-xl font-medium font-['Prompt'] mt-[70px] mb-[10px]"> eCAT-&gt;CAT</div>

        <div className="w-full h-[65px] justify-start items-start gap-2.5 inline-flex">
          <div className="w-full h-[65px] px-5 py-[5px] rounded border border-solid border-[#241C4C] flex-col justify-start items-center gap-2.5 inline-flex">
            <div className="py-1 justify-start items-center gap-2.5 inline-flex">
              <div className="opacity-70 text-white text-[10px] font-normal font-['Prompt'] leading-[10px]">
                {t('Total Staked', 'Total Staked')} eCAT
              </div>
            </div>
            <div className="justify-center items-center gap-1 inline-flex">
              <div className="text-white text-lg font-normal font-['Prompt']">
                <EthersValue value={costSavings?.stakeECat}></EthersValue> <span className="md:hidden">eCAT</span>
              </div>
            </div>
          </div>
          <div className="w-full h-[65px] px-5 py-[5px] rounded border border-solid border-[#241C4C] flex-col justify-start items-center gap-2.5 inline-flex">
            <div className="py-1 justify-start items-center gap-2.5 inline-flex">
              <div className="opacity-70 text-white text-[10px] font-normal font-['Prompt'] leading-[10px]">
                {t('Claimable', 'Claimable')}
              </div>
            </div>
            <div className="justify-center items-center gap-1 inline-flex">
              <div className="text-white text-lg font-normal font-['Prompt']">
                <EthersValue value={claimable}></EthersValue>
                <span className="md:hidden">CAT</span>
              </div>
            </div>
          </div>
        </div>

        <div className="mt-[10px]">
          <div className="w-full px-5 py-[5px] rounded border border-solid border-[#241C4C] flex-col justify-start items-center gap-2.5 inline-flex">
            <div className="w-full h-[18px] justify-between items-center inline-flex">
              <div className="opacity-70 text-white text-xs font-normal font-['Prompt']">eCAT</div>
              <div>
                <span className="text-purple-700 text-[10px] font-normal font-['Prompt']">
                  {t('Available', 'Available')}
                </span>
                <span className="text-white text-[10px] font-normal font-['Prompt']">
                  <EthersValue value={eCatBalance} balance></EthersValue>
                </span>
                <span className="text-purple-700 text-[10px] font-normal font-['Prompt']"> eCAT</span>
              </div>
            </div>

            <ConfigProvider
              theme={{
                components: {
                  Input: {
                    colorBgContainer: '#1B153A',
                    colorBorder: '#1B153A',
                    colorText: '#fff',
                  },
                },
                token: {
                  colorPrimary: '#7F68E2',

                  controlHeight: 52,
                  fontSize: 14,
                },
              }}
            >
              <Input
                value={value}
                disabled={loading}
                placeholder={t('Enter the amount', 'Enter the amount')}
                onChange={(e) => {
                  setValue(e.target.value);
                }}
                onBlur={(e) => {
                  setValue(e.target.value.replace(/[^\d.]/g, ''));
                }}
              ></Input>
            </ConfigProvider>
          </div>

          <div className="mt-[20px]">
            <div className="text-center">
              <span className="text-white text-sm font-bold font-['Prompt']">
                {t('Swap eCAT for CAT:', 'Swap eCAT for CAT:')} <br />
              </span>
              <span className="text-white text-xs font-normal font-['Prompt']">
                {t(
                  'Enter a 365-day linear vesting period, the transaction is final and irreversible',
                  'Enter a 365-day linear vesting period, the transaction is final and irreversible',
                )}
              </span>
            </div>
          </div>

          <div className="flex justify-center items-center gap-x-[10px] mt-[20px]">
            <ConnectButton>
              <ERC20ApproveButton
                allowance={`${value}`}
                tokenAddress={process.env.REACT_APP_ECAT_CONTRACT_ADDRESS}
                spenderAddress={process.env.REACT_APP_ECAT_STAKE_CONTRACT_ADDRESS}
              >
                <ConfigProvider
                  theme={{
                    components: {
                      Button: {
                        colorBgContainerDisabled: '#626262',
                        borderColorDisabled: '#626262',
                      },
                    },
                    token: {
                      colorPrimary: '#7F68E2',

                      controlHeight: 50,
                    },
                  }}
                >
                  <Button
                    type="primary"
                    block
                    className="mt-[10px]"
                    loading={loading}
                    // disabled
                    onClick={() => {
                      handleSummit();
                    }}
                  >
                    {t('Stake eCAT', 'Stake eCAT')}
                  </Button>
                </ConfigProvider>

                <ConfigProvider
                  theme={{
                    components: {
                      Button: {
                        colorBgContainerDisabled: '#626262',
                        borderColorDisabled: '#626262',
                      },
                    },
                    token: {
                      colorPrimary: '#7F68E2',

                      controlHeight: 50,
                    },
                  }}
                >
                  <Button
                    type="primary"
                    block
                    className="mt-[10px]"
                    ghost
                    loading={claimLoading}
                    // disabled
                    disabled={!canClaim}
                    onClick={() => {
                      runClaim();
                    }}
                  >
                    {canClaim ? t('Claim CAT', 'Claim CAT') : t('Nothing to claim', 'Nothing to claim')}
                  </Button>
                </ConfigProvider>
              </ERC20ApproveButton>
            </ConnectButton>
          </div>
          <History />
        </div>
      </div>
    </div>
  );
}

function History() {
  const { address } = useWallet();

  const { data } = useRequest(
    () => {
      return getLinearUnlocks({
        address: address!,
        take: 100,
        skip: 0,
      });
    },
    {
      ready: !!address,
      pollingInterval: 1000 * 10,
    },
  );

  const { t } = useTranslation();

  return (
    !!data?.data?.data?.length && (
      <>
        <div className="text-white text-xl font-medium font-['Prompt'] mt-[30px]">{t('History', 'History')}</div>

        {data?.data?.data.map((item, i) => {
          return (
            <div
              key={i}
              className="w-full p-2.5 rounded-[8px] border border-[#241C4C] flex-col justify-start items-start gap-2.5 inline-flex border-solid mt-[10px]"
            >
              <div className="self-stretch flex-col justify-start items-start gap-[5px] flex">
                <div className="self-stretch justify-between items-center inline-flex">
                  <div className="opacity-70 text-white text-xs font-normal font-['Prompt']">
                    {t('Staked eCAT', 'Staked eCAT')}
                  </div>
                  <div className="text-white text-[10px] font-normal font-['Prompt']">
                    <EthersValue value={item.value}></EthersValue> eCAT
                  </div>
                </div>
                <div className="self-stretch justify-between items-center inline-flex">
                  <div className="opacity-70 text-white text-xs font-normal font-['Prompt']">
                    {t('Create date', 'Create date')}
                  </div>
                  <div className="text-right text-white text-[10px] font-normal font-['Prompt']">{item.createdAt}</div>
                </div>
                {/* <div className="self-stretch justify-between items-center inline-flex">
                  <div className="opacity-70 text-white text-xs font-normal font-['Prompt']">
                    {t('Update date', 'Update date')}
                  </div>
                  <div className="text-right text-white text-[10px] font-normal font-['Prompt']">{item.updatedAt}</div>
                </div> */}
                <div className="self-stretch justify-between items-center inline-flex">
                  <div className="opacity-70 text-white text-xs font-normal font-['Prompt']">
                    {t('CAT unlocked', 'CAT unlocked')}
                  </div>
                  <div className="text-right text-white text-[10px] font-normal font-['Prompt']">
                    <EthersValue value={item.unlocked}></EthersValue> CAT
                  </div>
                </div>
              </div>
            </div>
          );
        })}
      </>
    )
  );
}
