// /v1/chain/claim?

import request from '@/common/request';

export const claim = async (payload: { address: string }) => {
  return request.get<{
    signature: string;
    erc20: string;
    maxAmount: string;
    deadline: string;
    tradedFee: string;
  }>('/v1/chain/claim', payload);
};

export const claimCat = async (payload: { address: string }) => {
  return request.get<{
    signature: string;
    erc20: string;
    maxAmount: string;
    deadline: string;
  }>('/v1/chain/claim/cat', payload);
};

export const getLinearUnlocks = async (payload: { address: string; take: number; skip: number }) => {
  return request.get<{
    data: {
      id: number;
      logId: number;
      address: string;
      tokenId: number;
      value: string;
      from: number;
      to: number;
      createdAt: string;
      updatedAt: string;
      unlocked: string;
    }[];
    count: number;
  }>('/v1/chain/linearUnlocks/cat', payload);
};
