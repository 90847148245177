import { login as loginRequest } from '@/service/login';
import { Account } from '../types/App';
import { useGlobalStore } from '@/stores/useGlobalStore';
import { useMemo } from 'react';
import { useNavigate } from 'react-router-dom';
import { useWalletStore } from '@/stores/useBitcoinWalletStore';
import { useWallet } from './bitcoinWallet/useWallet';
import { prepareAuth } from '@/service/whitelist';
import CryptoJS from 'crypto-js';

function calculateSHA256Hash(str: string) {
  const hash = CryptoJS.SHA256(str);

  const hexString = hash.toString(CryptoJS.enc.Hex);

  const byteArray = new Uint8Array(hexString.match(/.{1,2}/g)!.map((byte) => parseInt(byte, 16)));

  return byteArray;
}

function byteArrayToHexString(byteArray: Uint8Array) {
  return Array.from(byteArray, (byte) => ('0' + (byte & 0xff).toString(16)).slice(-2)).join('');
}

export function useAccount() {
  const { signMessage } = useWallet();

  const { clearAccount, account } = useWalletStore();

  const { clearToken, token, setToken } = useGlobalStore();

  const login = async (account: Account) => {
    const msgHashRes = await prepareAuth({ walletAddress: account.address! });

    // const message = byteArrayToHexString(calculateSHA256Hash(msgHashRes.data.messageToSign));
    const message = msgHashRes.data.messageToSign;

    const signature = await signMessage({ message });

    const loginRes = await loginRequest({
      signature,
      signedMessage: msgHashRes.data.messageToSign,
      inviteCode: '',
      signatureType: 'BTC',
    });

    setToken(loginRes.data.token);

    return loginRes;
  };

  const navigate = useNavigate();

  const loginOut = () => {
    clearAccount();
    clearToken();
    navigate('/', { replace: true });
  };

  const isAuth = useMemo(() => {
    return !!account && !!token;
  }, [account, token]);

  return {
    isAuth,
    login,
    loginOut,
  };
}
