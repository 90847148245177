import Header from '@/components/UI/Header';
import { Tabs, TabsProps } from 'antd';
import { useState } from 'react';
// import { t } from 'i18next';
import { useTranslation } from 'react-i18next';

import { Lp } from './LP';
import { Farm } from './Farm';

export default function () {
  const [key, setKey] = useState('1');

  const { t } = useTranslation();

  const items: TabsProps['items'] = [
    {
      key: '1',
      label: t('Farm', 'Farm'),
      children: <Farm />,
    },
    {
      key: '2',
      label: t('LP Pool', 'LP Pool'),
      children: <Lp />,
    },
  ];

  return (
    <div
      className="w-screen h-screen text-white pb-[30px] overflow-y-auto text-[16px]"
      style={{
        // backgroundImage: 'url(/static/images/default-background.png)',
        background: '#0F0B23',
        backgroundSize: '100% 100%',
      }}
    >
      <Header></Header>

      <div className="max-w-[640px] mx-auto md:px-[10px]">
        <div className="mt-[20px]">
          <Tabs
            className="w-full"
            centered
            defaultActiveKey={key}
            items={items}
            size="large"
            onChange={(key) => {
              setKey(key);
            }}
          />
        </div>
      </div>
    </div>
  );
}
