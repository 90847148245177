import { Preference } from '@/common/preferences';
import { create } from 'zustand';
// import { ethers } from 'ethers';
// import { parseBytes32String } from 'ethers/lib/utils';

interface State {
  token: string | null;

  setToken: (token: string) => void;

  clearToken: () => void;
}

export const useGlobalStore = create<State>((set) => ({
  token: Preference.getToken(),

  setToken(token: string) {
    Preference.setToken(token);

    set({
      token,
    });
  },

  clearToken() {
    Preference.clearToken();

    set({
      token: null,
    });
  },
}));
