import { ConfigProvider, theme } from 'antd';

export const Theme: React.FC<{ children: any }> = ({ children }) => (
  <ConfigProvider
    theme={{
      algorithm: theme.darkAlgorithm,
      components: {
        Button: {
          colorBgContainerDisabled: '#626262',
          borderColorDisabled: '#626262',
          controlHeight: 35,
          borderRadius: 4,
        },
        Input: {
          controlHeight: 38,
        },
        Dropdown: {
          colorBgElevated: '#1B153A',
        },
      },
      token: {
        colorPrimary: '#803ecf',
        fontFamily: 'DIN-Alternate-Bold, SourceSans3',
      },
    }}
  >
    {children}
  </ConfigProvider>
);
