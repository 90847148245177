import { Suspense } from 'react';
import { Route, Routes as ReactRoutes, Navigate } from 'react-router-dom';
import DefaultLayout from '@/components/Layout/Default';
import NProgress from '@/components/UI/NProgress';

// import Index from '@/views/Index';
import Whitelist from '@/views/Whitelist';
import CatStaking from '@/views/Earn/CatStaking';
import TradeToEarn from '@/views/Earn/TradeToEarn';
import ECat from '@/views/Earn/ECat';
import IDO from '@/views/Earn/IDO';
import Vault from '@/views/Earn/Vault';
import Farm from '@/views/Earn/Farm';
import ECatIDO from '@/views/ECatIDO';

// const About = lazy(() => import('@/views/About'));

const Routes = () => {
  return (
    <Suspense fallback={<NProgress />}>
      <ReactRoutes>
        <Route path="/" element={<DefaultLayout />}>
          {/* <Route path="/" key="Index" element={<Index />} /> */}
          <Route path="/whitelist" key="Whitelist" element={<Whitelist />} />
          <Route path="*" element={<Navigate to="/" />} />
          <Route path="/IDO" key="IDO" element={<IDO />} />
          <Route path="/" key="catStaking" element={<CatStaking />} />
          <Route path="/eCatIDO" key="eCatIDO" element={<ECatIDO />} />
          <Route path="/eCat" key="ECat" element={<ECat />} />
          <Route path="/farm" key="Farm" element={<Farm />} />
          <Route path="/tradeToEarn" key="TradeToEarn" element={<TradeToEarn />} />
          <Route path="/earn" element={<DefaultLayout />}>
            <Route path="catStaking" key="catStaking" element={<CatStaking />} />
            <Route path="vault" key="Vault" element={<Vault />} />
          </Route>
        </Route>
      </ReactRoutes>
    </Suspense>
  );
};

export default Routes;
