import request from '@/common/request/vault';

export const getClaimHistory = async (payload: { cUSDTAddr: string; amount: number }) => {
  return request.get<{ amount: number; estimated_epoch: number; withdraw_date: string }[]>(
    '/main/api/v1/vault/depositHistory',
    payload,
  );
};

export const getWithdrawHistory = async (payload: { cUSDTAddr: string; addr: string }) => {
  return request.get<{ amount: number; estimated_epoch: number; withdraw_date: string }[]>(
    '/main/api/v1/vault/withdrawHistory',
    payload,
  );
};
