import { Button, ConfigProvider, message } from 'antd';
import React, { useState } from 'react';
import { WalletModal } from '../WalletModal';
import { useWallet } from '@/common/hooks/bitcoinWallet/useWallet';
import { useRequest } from 'ahooks';
import { Account } from '@/common/types/App';
import { RightCircleOutlined } from '@ant-design/icons';
import classNames from 'classnames';
import { useWalletStore } from '@/stores/useBitcoinWalletStore';
import { useAccount } from '@/common/hooks/useAccount';

export const ConnectButton: React.FC<{
  children?: any;
  className?: string;
  textMode?: boolean;
}> = ({ children, className, textMode }) => {
  const { isAuth, login } = useAccount();

  const [open, setOpen] = useState(false);

  const { clearAccount, account } = useWalletStore();

  const { run, loading } = useRequest(
    (account: Account) => {
      return login(account);
    },
    {
      manual: true,
      onSuccess() {
        message.success('Login success');
      },
      onError(err) {
        console.log('err', err);
        clearAccount();
        message.error('Login failed');
      },
    },
  );

  return (
    <>
      {account ? (
        children
      ) : (
        <ConfigProvider
          theme={{
            token: {
              colorPrimary: '#7F68E2',
              
              controlHeight: 50,
              fontSize: 20,
            },
          }}
        >
          <Button type="primary" className={className} onClick={() => setOpen(true)} block>
            Connect
          </Button>
        </ConfigProvider>
      )}

      <WalletModal
        open={open}
        onConnected={(account) => {
          setOpen(false);
          run(account);
        }}
        onClose={() => {
          setOpen(false);
        }}
      ></WalletModal>
    </>
  );
};
