import { FarmStake__factory, LPStaking__factory } from '@/common/contracts/typechain';
import { useWallet } from '@/common/hooks/wagmi/useWallet';
import { parseUnits } from 'ethers/lib/utils';

export function useTokenContract() {
  const { provider } = useWallet();

  function getContract() {
    return FarmStake__factory.connect(process.env.REACT_APP_FARM_STAKE_CONTRACT_ADDRESS!, provider);
  }

  async function stake(amount: string) {
    const tx = await getContract().stake(parseUnits(amount));

    const res = await tx.wait();

    return res;
  }

  async function claim(data: { maxBalance: any; maxReward: any; deadline: any; signature: any }) {
    const tx = await getContract().claimAll(data.maxBalance, data.maxReward, data.deadline, data.signature);

    const res = await tx.wait();

    return res;
  }

  async function getStakeInfo(addr: string) {
    const [balance, balanceClaimed, rewardClaimed] = await getContract().users(addr);

    return {
      balance,
      balanceClaimed,
      rewardClaimed,
    };
  }

  return {
    stake,
    claim,
    getStakeInfo,
  };
}
