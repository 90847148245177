type Engine = 'sessionStorage' | 'localStorage';

class Storage {
  private engine: Engine = 'sessionStorage';
  private prefix = '';

  get storage() {
    return window[this.engine] as globalThis.Storage;
  }

  get len() {
    return this.storage.length;
  }

  constructor({ engine, prefix }: { engine: Engine; prefix: string } = { engine: 'sessionStorage', prefix: '' }) {
    this.engine = engine;
    this.prefix = prefix;
  }

  /**
   * @param key
   */
  public get<T = any>(key: string): T | null {
    try {
      const data = this.storage.getItem(this.addPrefix(key));

      return data && JSON.parse(data);
    } catch {
      return null;
    }
  }

  /**
   * @param key
   * @param value
   */
  public set(key: string, value: any) {
    try {
      this.storage.setItem(this.addPrefix(key), JSON.stringify(value));

      return true;
    } catch {
      return false;
    }
  }

  /**
   * @param key
   */
  public delete(key: string) {
    this.storage.removeItem(this.addPrefix(key));
  }

  private addPrefix(key: string) {
    return `${this.prefix}_${key}`;
  }
}

export default Storage;
