import { useWallet } from '@/common/hooks/bitcoinWallet/useWallet';
import { useAccount } from '@/common/hooks/useAccount';
import { shortenAddress } from '@/common/utils';
import { ConnectButton } from '@/components/App/BitcoinConnectButton';
import Header from '@/components/UI/Header';
import { getHasRegistered, getWhitelistStatus, submitAddress } from '@/service/whitelist';
import { useGlobalStore } from '@/stores/useGlobalStore';
import { DisconnectOutlined } from '@ant-design/icons';
import { useRequest } from 'ahooks';
import { Button, ConfigProvider, Input, message } from 'antd';
import { BigNumber, ethers } from 'ethers';
import { useMemo, useState } from 'react';
import { useWallet as useEthWallet } from '@/common/hooks/wagmi/useWallet';
import { formatUnits, parseUnits } from 'ethers/lib/utils';
import snapshot from './snapshot.json';
import { useTranslation } from 'react-i18next';

function Index() {
  const { address } = useWallet();

  const { address: ethAddress } = useEthWallet();

  const { token } = useGlobalStore();

  const { loginOut } = useAccount();

  const { t } = useTranslation();

  const [walletAddress, setWalletAddress] = useState('');

  const { data, refresh } = useRequest(
    () => {
      return getWhitelistStatus();
    },
    { ready: !!token },
  );

  const isLegal = useMemo(() => {
    try {
      const item = snapshot.find((item) => item.address.toLowerCase() === address?.toLowerCase());

      return item!.overallBalance >= 5000;
    } catch {
      return false;
    }
  }, [address]);

  const displayNumber = useMemo(() => {
    try {
      const item = snapshot.find((item) => item.address.toLowerCase() === address?.toLowerCase());

      return isLegal ? item?.overallBalance : '-';
    } catch {
      return '-';
    }
  }, [data?.data?.bicaBalance, isLegal]);

  // const { data: hasRegistered } = useRequest(
  //   () => {
  //     return getHasRegistered({ walletAddress: address! });
  //   },
  //   { ready: !!address },
  // );

  const { run } = useRequest(
    () => {
      return submitAddress({ content: walletAddress });
    },
    {
      manual: true,
      onSuccess() {
        message.success(t('Submit success', 'Submit success'));
        refresh();
      },
      onError(err) {
        console.log('err', err);
      },
    },
  );

  const handleSubmit = () => {
    const isValid = ethers.utils.isAddress(walletAddress);

    if (!isValid) {
      return message.warning(t('Invalid address', 'Invalid address'));
    }

    run();
  };

  return (
    <div
      className="w-screen h-screen"
      style={{
        backgroundImage: 'url(/static/images/whitelist/background.png)',
        backgroundSize: '100% 100%',
      }}
    >
      <Header></Header>

      <div className="w-[410px] md:w-full mx-auto mt-[83px] md:px-[20px]">
        <div className="flex justify-between items-center">
          <div className="text-white text-xl font-medium font-['Prompt']">{t('Whitelist', 'Whitelist')}</div>
          {/* <div className="text-violet-500 text-xl font-medium font-['Prompt']">Get $BICA</div> */}
        </div>

        <div className=" w-full border border-solid border-primary rounded-md py-[22px] px-[20px] mt-[10px]">
          <div className="flex justify-between items-center">
            <div className="justify-start items-center gap-2.5 flex">
              <img className="rounded-full" src="/static/images/icon/bitcoin.svg" />
              <div className="text-white text-xl font-medium font-['Prompt']">$BICA</div>
            </div>

            <div className="flex-col justify-start items-end inline-flex">
              <div className="py-1 justify-start items-center gap-2.5 inline-flex">
                <div className="opacity-70 text-white text-[10px] font-normal font-['Prompt'] leading-[10px]">
                  {t('Snapshot Balance', 'Snapshot Balance')}
                </div>
              </div>
              <div className="justify-center items-center gap-1 inline-flex">
                <div className="text-white text-lg font-normal font-['Prompt']">{displayNumber}</div>
              </div>
            </div>
          </div>

          {token && (
            <div className="mt-[30px] mb-[20px]">
              {isLegal ? (
                <div className="text-white flex justify-center items-center gap-x-[10px]">
                  <img src="/static/images/icon/efficient.svg" />{' '}
                  {t('Congratulations! You are on the whitelist', 'Congratulations! You are on the whitelist')}
                </div>
              ) : (
                <div className="text-white flex justify-center items-center gap-x-[10px]">
                  <img src="/static/images/icon/invalid.svg" />
                  {t('Feel sorry! You are not in the whitelist', 'Feel sorry! You are not in the whitelist')}
                </div>
              )}
            </div>
          )}
          <div></div>

          <ConnectButton className="mt-[25px]">
            <div className="text-white w-full flex justify-between items-center">
              {shortenAddress(address)}
              <DisconnectOutlined
                onClick={() => {
                  loginOut();
                }}
                
                
              />
            </div>
          </ConnectButton>
        </div>

        <div className="p-2.5 bg-indigo-950 rounded-[8px] flex-col justify-start items-start gap-5 inline-flex w-full mt-[21px]">
          <div className="self-stretch flex-col justify-start items-start gap-2.5 flex">
            <div className="self-stretch min-h-[71px] flex-col justify-start items-end gap-[5px] flex">
              <div className="self-stretch justify-start items-center gap-[58px] inline-flex">
                <div className="opacity-70 text-white text-xs font-normal font-['Prompt']">
                  {!data?.data?.submitted
                    ? t(
                        'You need a BEP20 Address to participate in eCAT IDO',
                        'You need a BEP20 Address to participate in eCAT IDO',
                      )
                    : t('You have submitted your address', 'You have submitted your address')}
                </div>
              </div>
              <div className="self-stretch  bg-indigo-950 rounded-[8px] justify-start items-center gap-2 inline-flex mt-[5px]">
                {!data?.data?.submitted ? (
                  <ConfigProvider
                    theme={{
                      components: {
                        Input: {
                          colorBgContainer: '#1B153A',
                          colorBorder: '#1B153A',
                          colorText: '#fff',
                        },
                      },
                      token: {
                        colorPrimary: '#7F68E2',

                        controlHeight: 50,
                        fontSize: 20,
                      },
                    }}
                  >
                    <Input
                      disabled={!isLegal}
                      value={walletAddress}
                      onChange={(v) => {
                        setWalletAddress(v.target.value);
                      }}
                    ></Input>
                  </ConfigProvider>
                ) : (
                  <div className="w-full text-white">
                    {data?.data?.submitted ? shortenAddress(data?.data?.submitted) : '-'}
                  </div>
                )}
              </div>
            </div>
            {!data?.data?.submitted && (
              <div className="self-stretch justify-start items-start gap-5 inline-flex">
                <ConfigProvider
                  theme={{
                    components: {
                      Button: {
                        colorBgContainerDisabled: '#626262',
                        borderColorDisabled: '#626262',
                      },
                    },
                    token: {
                      colorPrimary: '#7F68E2',

                      controlHeight: 50,
                      fontSize: 20,
                    },
                  }}
                >
                  <Button
                    onClick={() => {
                      handleSubmit();
                    }}
                    disabled={!walletAddress || !isLegal}
                    type="primary"
                    block
                    className="mt-[10px]"
                  >
                    {t('Submit', 'Submit')}
                  </Button>
                </ConfigProvider>
              </div>
            )}
          </div>
          {/* <div className="self-stretch h-[18px] flex-col justify-start items-end gap-[5px] flex">
            <div className="self-stretch justify-between items-center inline-flex">
              <div className="opacity-70 text-white text-xs font-normal font-['Prompt']">Connected Address</div>
              <div className="text-white text-xs font-normal font-['Prompt']">
                {ethAddress ? shortenAddress(ethAddress) : '-'}
              </div>
            </div>
          </div> */}
        </div>
      </div>
    </div>
  );
}

export default Index;
