import { Finance__factory, Ido__factory } from '@/common/contracts/typechain';
import { useWallet } from '@/common/hooks/wagmi/useWallet';

export function useIDO() {
  const { provider } = useWallet();

  function getContract() {
    return Ido__factory.connect(process.env.REACT_APP_IDO_CONTRACT_ADDRESS!, provider);
  }

  async function claim() {
    const tx = await getContract().claim();

    const res = await tx.wait();

    return res;
  }

  async function getHistory(address: string) {
    const res = await getContract().history(address);

    return res;
  }

  async function getClaimInfo(address: string) {
    const [claimable, claimed, nextReleaseTime, canClaim] = await getContract().claimInfo(address);

    return {
      claimable,
      claimed,
      nextReleaseTime,
      canClaim,
    };
  }

  return {
    claim,
    getHistory,
    getClaimInfo,
  };
}
