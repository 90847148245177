import { storageKeys } from '../constants/storage';
import { Account, WalletKey } from '../types/App';
import storage from './storage';

export class Preference {
  static setCurrentWallet(wallet: WalletKey) {
    storage.set(storageKeys.currentWallet as any, wallet);
  }

  static getCurrentWallet(): WalletKey {
    return storage.get(storageKeys.currentWallet as any) as WalletKey;
  }

  static clearCurrentWallet() {
    storage.del(storageKeys.currentWallet as any);
  }

  static setCurrentWagmiWallet(wallet: string) {
    storage.set(storageKeys.currentWagmiWallet as any, wallet);
  }

  static getCurrentWagmiWallet(): string {
    return storage.get(storageKeys.currentWagmiWallet as any) as WalletKey;
  }

  static clearCurrenWagmiWallet() {
    storage.del(storageKeys.currentWagmiWallet as any);
  }

  static getAccount() {
    return storage.get(storageKeys.account) as Account | null;
  }

  static setAccount(account: Account) {
    storage.set(storageKeys.account, account);
  }

  static clearAccount() {
    storage.del(storageKeys.account);
  }

  static setToken(token: string) {
    storage.set(storageKeys.token, token);
  }

  static getToken() {
    return storage.get(storageKeys.token) as string | null;
  }

  static clearToken() {
    storage.del(storageKeys.token);
  }
}
