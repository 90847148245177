import { useChain } from '@/common/hooks/wagmi/useChain';
import { useWallet } from '@/common/hooks/wagmi/useWallet';
import { Preference } from '@/common/preferences';
import { useNameServiceStore } from '@/stores/useNameService';
import { LoadingOutlined } from '@ant-design/icons';
import { Button, Modal } from 'antd';
import { useEffect, useMemo, useState } from 'react';

export function Initialization({ children }: { children: any }) {
  const [modal, contextHolder] = Modal.useModal();

  const [ready, setReady] = useState(true);

  const nameServiceStore = useNameServiceStore();

  const { address, isConnected, connect, connectors, currentConnector } = useWallet({
    onAccountChange() {
      modal.info({
        content: 'Your account has changed, the page will be refreshed',
        onOk() {
          window.location.reload();
        },
      });
    },
    onChainChange() {
      modal.info({
        content: 'Your chain has changed, the page will be refreshed',
        onOk() {
          window.location.reload();
        },
      });
    },
    onError(err) {
      console.log(err);
    },
  });

  const { switchNetwork, isLoading, chains, error, chain } = useChain();

  const showChainAlert = useMemo(() => {
    return address && chain?.id !== 56;
  }, [address, chain?.id]);

  const handleSwitch = (chainId: number) => {
    return switchNetwork?.(chainId);
  };

  const autoConnect = async () => {
    try {
      // const isAuth = await connectors[0].isAuthorized();

      const name = Preference.getCurrentWagmiWallet();

      if (name) {
        const item = connectors.find((item) => item.name === name);

        connect({ connector: item });
      }
    } catch (err) {
      console.log('err');
    }
  };

  useEffect(() => {
    autoConnect();

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return (
    <>
      {children}
      {contextHolder}
    </>
  );
}
