import { CToken__factory, CatStake__factory } from '@/common/contracts/typechain';
import { useWallet } from '@/common/hooks/wagmi/useWallet';
import { BigNumberish } from 'ethers';
import { parseUnits } from 'ethers/lib/utils';

export function useVault() {
  const { provider } = useWallet();

  function getContract() {
    return CToken__factory.connect(process.env.REACT_APP_VAULT_CONTRACT_ADDRESS!, provider);
  }

  function tvl() {
    return getContract().tvl();
  }

  function collateralizationP() {
    return getContract().collateralizationP();
  }

  function shareToAssetsPrice() {
    return getContract().shareToAssetsPrice();
  }

  function totalSupply() {
    return getContract().totalSupply();
  }

  function balanceOf(address: string) {
    return getContract().balanceOf(address);
  }

  async function deposit(address: string, amount: string) {
    const tx = await getContract().deposit(parseUnits(amount), address);

    await tx.wait();

    return tx;
  }

  async function makeWithdrawRequest(address: string, amount: string) {
    const tx = await getContract().makeWithdrawRequest(parseUnits(amount), address);

    await tx.wait();

    return tx;
  }

  async function withdraw(address: string, amount: BigNumberish) {
    const tx = await getContract().redeem(amount, address, address);

    await tx.wait();

    return tx;
  }

  async function epochInfo() {
    const [currentEpoch, currentEpochStart, ePochTimeDuration] = await getContract().epochInfo();

    return {
      currentEpoch,
      currentEpochStart,
      ePochTimeDuration,
    };
  }

  function totalSharesBeingWithdrawn(address: string) {
    return getContract().totalSharesBeingWithdrawn(address);
  }

  function withdrawRequests(currentEpoch: any, address: string) {
    return getContract().withdrawRequests(address, currentEpoch);
  }

  function withdrawEpochsTimelock() {
    return getContract().withdrawEpochsTimelock();
  }

  function assetsDeposits(address: string) {
    return getContract().assetsDeposits(address);
  }

  function pauseDeposit() {
    return getContract().pauseDeposit();
  }

  function pauseRedeem() {
    return getContract().pauseRedeem();
  }

  return {
    tvl,
    collateralizationP,
    shareToAssetsPrice,
    totalSupply,
    balanceOf,
    deposit,
    makeWithdrawRequest,
    withdraw,
    epochInfo,
    totalSharesBeingWithdrawn,
    withdrawRequests,
    withdrawEpochsTimelock,
    assetsDeposits,
    pauseDeposit,
    pauseRedeem,
  };
}
