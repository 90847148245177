import React from 'react';
import { Outlet } from 'react-router-dom';
import Header from '@/components/UI/Header';

function DefaultLayout() {
  return (
    <>
      <Outlet />
    </>
  );
}

export default DefaultLayout;
