import { useWallet } from '@/common/hooks/wagmi/useWallet';
import { Button } from 'antd';
import React, { useMemo, useState } from 'react';
import { Bep20WalletModal } from '../Bep20WalletModal';
import { useTranslation } from 'react-i18next';
import Md5 from 'js-md5';
import { JSEncrypt } from 'jsencrypt';
import CryptoJS from 'crypto-js';
import { auth } from '@/service/login';

const LOGIN_PRIVATE = `-----BEGIN RSA Private Key-----
MIIEpAIBAAKCAQEAq8YrFOeEpw34GHIbwKouscO/qWeeoFW30Ymlmu7ilca+eko4
ngktASbfh3lwSjmnCmSMxy2HNxKadZoO/qhtFlQWSB9OK0FAgY9F9V08q/+u2ewr
Xc9XN7zfRW7sotj/wc+gYAKYD6yZvoJjbMB5JAIICCwtV+Zmgj2wXyc4G1gbujsB
/1QIWM60XnnA6i0W/6GxN5n1s5jDmQNUTT654xjR1dQroq2X7FACeDOoCHFwRBRq
CLMFaMyQnbTs+jCw7Y6DOvUni7Za8kB5faIvk/YxEAuEvGoCdns1bY6dBqXbl1Z6
h0lC8rh1SZ/jrcD3JNgkDkTJ3C+QnA5gwk5KLQIDAQABAoIBAQCFo2AjHFfkzAZn
pmcXBo/bdypmtpn/rZCcMZWoqw+XbQ8F/zZ7JTJhQggH0x/cb2wYts+bqj0XSJ7o
vZnK3+g2xear58cd+d8grQHFeetY+UvkeUvn6Qa283dVAo6Rx3d7JnhkGcCJ+UOH
ptOJaVkV/YqYKR6B01uPGkVcUhvhkOzh01/46rSVNM4BcmWteGCbqGVSFVaJWQ4P
xFtlW7q1tsW7NdSEHdUKkZoET89zZjIxVGeR1JJmWAVnVDXghd7IBoL5xQfau1m9
iuu66OEBPdnL9H8eTlmcc4sWmiQsDFIU01zv0iQ2cCzLTAAWowdormv64CgPYNZO
Q+uIsdu1AoGBAN7wFGKZWNbX3kRQd92qM20c7VD/AXeAWOnpUu350aXKvOHLeyrv
jE21x6+672uQg3qkxdDphenMoITWKxWmDb/FmAjqFenr8wkHtfCpG2zSL8HyqpkI
Z20YPlDYM/eiL5UFw7WmKLtxdGDNrMxSxVmz6gHP1zCQVpJoRh50hlNDAoGBAMU/
o2r2uZkVxDPfgw3u4fMXBbByix/PH/sPnv3RSgCSW/h3LsDauRcy4569rdtWniJp
NsjZYplnXssb0OQDRC3DhgPSUMUYBbGZinZ8Fd8ei/sNio7+U7D5VRIfRETj2C/1
d3d3aCg5xYW5EJohmoQNTTS6scSJ2yng4uLCHj3PAoGAR44RTPBhRlv1GEInpvsZ
FH2DYkrnAp8saq+cEYvskWU4KNTxzH8krlCZLjs223R/7t5yK7K4a6llIB3JqGIq
6ZGXUc0oiXNVB2H1MLBmgBAaigrEXOgIokh0v+gVDfd9hDxca7JQQpGQw/1f6Bjt
BPStODWooQrC7q0PTHkkIBcCgYEApjZXBZZ8lTRTTgblj4Mh4U8gGyyacaZ+43ep
WWbX/h1W5MvBmP92gsb+KdDaRP77aDHS2XLddDk4Ft0MrGbYN9wP/P5n033Hpn2k
wO0VHRvHhzlE7fQAIBwqhIfWZlq83MTy87eqDGOKP4HQLKFubCItUeaUT/J+vQ5l
AgHjq98CgYAqodSSU1g+xlKW0R8DvbutxKYmatGA/PBmwpeELIeefgxumLdJdDNg
i23iqoJTcUfaDzaAK4dpKlCEGAl1sZe/WJlB8KkuaPpJIoRxQL/ZyjoqIjuV/mh1
1YeBeRS4NqSOZZ6447owQvE8VnD0O2a+2pyyr47WZyOLBciIBhqvRQ==
-----END RSA Private Key-----
`;

const setSingatureString = (obj: any) => {
  // 请求参数按 ASCII 码递增排序（字母升序排序），组合成 参数=参数值 的格式，并用 & 字符连接起来，得到d1
  const keys = Object.keys(obj).sort();
  let str = '';
  keys.forEach((x, i) => {
    if (i !== 0) {
      str += '&';
    }
    str += `${x}=${obj[x]}`;
  });
  // 对d1作string.toLowerCase()后，进行md5处理，得到d2
  str = str.toLocaleLowerCase();

  str += `&hash=${(Md5 as any)(str)}`;

  return str;
};

function sign({ address }: { address: string }) {
  const add = address;

  const str = setSingatureString({ address: add, code: '' });

  const sign = new JSEncrypt();

  sign.setPrivateKey(LOGIN_PRIVATE);

  const signature = sign.sign(str, (CryptoJS as any).SHA256, 'sha256');

  return signature;
}

export const ConnectButton: React.FC<{ children?: any; className?: string }> = ({ children, className }) => {
  const { address, displayAddress, connectors, isLoading, pendingConnector } = useWallet();

  const connector = useMemo(() => {
    return connectors?.[0];
  }, [connectors]);

  const { t } = useTranslation();

  const connectButtonText = useMemo(() => {
    if (!connector.ready) {
      return t('Unsupported', 'Unsupported');
    }

    if (isLoading && connector.id === pendingConnector?.id) {
      return t('Connecting', 'Connecting');
    }

    return t('Connect Wallet', 'Connect Wallet');
  }, [connector.id, connector.ready, isLoading, pendingConnector?.id, t]);

  const [open, setOpen] = useState(false);

  return address ? (
    children
  ) : (
    <>
      <Button
        type="primary"
        className={className}
        block
        disabled={!connector?.ready}
        key={connector?.id}
        loading={isLoading}
        onClick={() => setOpen(true)}
      >
        {address ? displayAddress : connectButtonText}
      </Button>

      <Bep20WalletModal
        open={open}
        onConnected={(address) => {
          auth({
            address,
            sign: sign({ address: address }) as string,
            code: '',
          }).catch((err) => {
            console.log(err);
          });
        }}
        onClose={() => {
          setOpen(false);
        }}
      />
    </>
  );
};
