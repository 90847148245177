import { useWallet } from '@/common/hooks/wagmi/useWallet';
import Header from '@/components/UI/Header';
import { Button, ConfigProvider, message } from 'antd';
import { useState } from 'react';
import { useIDO } from './useIDO';
import { useRequest } from 'ahooks';
import { claim as claimRequest } from '@/service';
import { ConnectButton } from '@/components/App/ConnectButton';
import { EthersValue } from '@/components/App/EthersValue';
import { useTranslation } from 'react-i18next';

export default function () {
  const { claim, getClaimInfo } = useIDO();

  const { provider, address } = useWallet();

  const { t } = useTranslation();

  const { run, loading } = useRequest(
    async () => {
      return claim();
    },
    {
      manual: true,
      onError() {
        message.error(t('Claim failed', 'Claim failed'));
      },
      onSuccess() {
        message.success(t('Claim success', 'Claim success'));
      },
    },
  );

  const { data } = useRequest(
    async () => {
      return getClaimInfo(address!);
    },
    {
      ready: !!provider,
      onError(err) {
        console.log(err);
        message.error(t('Claim Info failed', 'Claim Info failed'));
      },
    },
  );

  return (
    <div
      className="w-screen h-screen pb-[30px]"
      style={{
        // backgroundImage: 'url(/static/images/default-background.png)',
        background: '#0F0B23',
        backgroundSize: '100% 100%',
      }}
    >
      <Header></Header>

      <div className="max-w-[640px] md:w-full mx-auto flex justify-between items-start gap-x-[53px] mt-[80px] md:flex-wrap md:justify-center md:px-[20px]">
        <div className=" w-[410px] h-[251.11px] relative md:w-full">
          <div className="text-white text-xl font-medium font-['Prompt']">{t('CLAIM', 'CLAIM')}</div>

          <div className="w-[410px] md:w-full h-[140px] justify-start items-start gap-2.5 inline-flex flex-wrap mt-[10px]">
            <div className="w-1/2 px-5 py-[5px] rounded border border-solid border-[#241C4C] flex-col justify-start items-center gap-2.5 inline-flex">
              <div className="py-1 justify-start items-center gap-2.5 inline-flex">
                <div className="opacity-70 text-white text-[10px] font-normal font-['Prompt'] leading-[10px]">
                  {t('Claimable', 'Claimable')}
                </div>
              </div>
              <div className="justify-center items-center gap-1 inline-flex">
                <div className="text-white text-lg font-normal font-['Prompt']">
                  <EthersValue value={data?.claimable}></EthersValue> CAT
                </div>
              </div>
            </div>

            <div className="w-1/2 grow shrink basis-0 h-[65px] px-5 py-[5px] rounded border border-solid border-[#241C4C] flex-col justify-start items-center gap-2.5 inline-flex">
              <div className="py-1 justify-start items-center gap-2.5 inline-flex">
                <div className="opacity-70 text-white text-[10px] font-normal font-['Prompt'] leading-[10px]">
                  {t('Claimed', 'Claimed')}
                </div>
              </div>
              <div className="justify-center items-center gap-1 inline-flex">
                <div className="text-white text-lg font-normal font-['Prompt']">
                  {' '}
                  <EthersValue value={data?.claimed}></EthersValue> CAT
                </div>
              </div>
            </div>

            <div className="w-full h-[65px] px-5 py-[5px] rounded border border-solid border-[#241C4C] flex-col justify-start items-center gap-2.5 inline-flex">
              <div className="py-1 justify-start items-center gap-2.5 inline-flex">
                <div className="opacity-70 text-white text-[10px] font-normal font-['Prompt'] leading-[10px]">
                  {t('Next Release Time', 'Next Release Time')}
                </div>
              </div>
              <div className="justify-center items-center gap-1 inline-flex">
                <div className="text-white text-lg font-normal font-['Prompt']">
                  {data?.nextReleaseTime?.toString()}
                </div>
              </div>
            </div>
          </div>
          <div className="w-full h-[50px] left-0 top-[201.11px]  justify-start items-start gap-5 inline-flex mt-[20px]">
            <ConnectButton>
              <ConfigProvider
                theme={{
                  components: {
                    Button: {
                      colorBgContainerDisabled: '#626262',
                      borderColorDisabled: '#626262',
                    },
                  },
                  token: {
                    colorPrimary: '#0AAE55',

                    controlHeight: 50,
                    fontSize: 20,
                  },
                }}
              >
                <Button
                  type="primary"
                  block
                  loading={loading}
                  disabled={!data?.canClaim}
                  onClick={() => {
                    run();
                  }}
                >
                  {t('Claim', 'Claim')}
                </Button>
              </ConfigProvider>
            </ConnectButton>
          </div>

          <History />
        </div>
      </div>
    </div>
  );
}

function History() {
  const { address, provider } = useWallet();

  const { getHistory } = useIDO();

  const { data } = useRequest(
    async () => {
      return getHistory(address!);
    },
    {
      ready: !!provider,
    },
  );

  const { t } = useTranslation();

  return (
    !!data?.length && (
      <>
        <div className="text-white text-xl font-medium font-['Prompt'] mt-[30px]">{t('History', 'History')}</div>
        {data?.map((item, index) => {
          return (
            <div
              key={index}
              className="w-full  p-2.5 rounded-[8px] border border-[#241C4C] flex-col justify-start items-start gap-2.5 inline-flex border-solid mt-[10px]"
            >
              <div className="self-stretch flex-col justify-start items-start gap-[5px] flex">
                <div className="self-stretch justify-between items-center inline-flex">
                  <div className="opacity-70 text-white text-xs font-normal font-['Prompt']">
                    {t('Amount', 'Amount')}
                  </div>
                  <div className="text-right text-white text-[10px] font-normal font-['Prompt']">
                    <EthersValue value={item.amount}></EthersValue>
                  </div>
                </div>
                <div className="self-stretch justify-between items-center inline-flex">
                  <div className="opacity-70 text-white text-xs font-normal font-['Prompt']">
                    {t('Claim date', 'Claim date')}
                  </div>
                  <div className="text-white text-[10px] font-normal font-['Prompt']">{item.claimTime?.toString()}</div>
                </div>
              </div>
            </div>
          );
        })}
      </>
    )
  );
}
