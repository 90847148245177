import { configureChains, createConfig } from 'wagmi';
import { publicProvider } from 'wagmi/providers/public';
import { MetaMaskConnector } from '@wagmi/core/connectors/metaMask';
import { bsc } from '@wagmi/core/chains';
import { InjectedConnector } from 'wagmi/connectors/injected';

export function useClient() {
  const chains = [bsc];

  const { publicClient } = configureChains(chains, [publicProvider()]);

  const client = createConfig({
    autoConnect: false,
    connectors: [
      new InjectedConnector({
        chains,
        options: {
          name: 'BinanceWallet',
          shimDisconnect: true,
          getProvider() {
            return window.ethereum;
          },
        },
      }),
      new MetaMaskConnector({
        chains,
      }),
      new InjectedConnector({
        chains,
        options: {
          name: 'Bitkeep',
          shimDisconnect: true,
          getProvider() {
            return window.bitkeep?.ethereum;
          },
        },
      }),

      new InjectedConnector({
        chains,
        options: {
          name: 'OKX Wallet',
          shimDisconnect: true,
          getProvider() {
            return window.okxwallet;
          },
        },
      }),
      // new InjectedConnector({
      //   chains,
      //   options: {
      //     name: 'TrustWallet',
      //     shimDisconnect: true,
      //     getProvider() {
      //       return window.trustwallet;
      //     },
      //   },
      // }),
      new InjectedConnector({
        chains,
        options: {
          name: 'GateWallet',
          shimDisconnect: true,
          getProvider() {
            return window.gatewallet;
          },
        },
      }),
    ],
    publicClient,
  });

  return {
    client,
  };
}

// Metamask: window.ethereum,
// Bitkeep: window.bitkeep?.ethereum,
// OKX: window.okxwallet,
// TrustWallet: window.trustwallet,
// BinanceWallet: window.ethereum,
// GateWallet: window.gatewallet,
