import { useEffect } from 'react';
import { WalletPayload } from './useWallet';
import { useWalletStore } from '@/stores/useBitcoinWalletStore';

export function useUniSat(payload?: WalletPayload) {
  const unisat = window.unisat;

  const { setAccount, clearAccount, account } = useWalletStore();

  const connect = () => {
    unisat
      ?.requestAccounts()
      .then(async (accounts: string[]) => {
        const publicKey = await unisat.getPublicKey();

        const account = {
          address: accounts[0],
          publicKey,
          compressedPublicKey: publicKey,
        } as any;

        setAccount(account);

        payload?.onConnect?.(account);
      })
      .catch((err: any) => {
        payload?.onConnectError?.();

        return Promise.reject(err);
      });
  };

  // const getAccounts = () => {
  //   unisat
  //     ?.getAccounts()
  //     .then((accounts: string[]) => {
  //       setCurrentWallet({ address: accounts[0] } as any);
  //     })
  //     .catch((err: any) => {
  //       return Promise.reject(err);
  //     });
  // };

  // async function getNetwork() {
  //   try {
  //     const res = await unisat?.getNetwork();

  //     return res;
  //   } catch (e) {
  //     return Promise.reject(e);
  //   }
  // }

  function signMessage({ message }: { message: string }) {
    try {
      return unisat?.signMessage(message);
    } catch (e) {
      return Promise.reject(e);
    }
  }

  function disconnect() {
    payload?.onDisconnect?.();
    clearAccount();
  }

  useEffect(() => {
    if (!unisat) return;

    const accountsChanged = (accounts: any) => {
      if (!accounts.length) {
        disconnect();
      } else {
        payload?.onAccountChange?.();
      }
    };

    unisat.on('accountsChanged', accountsChanged);

    return () => {
      unisat.removeListener('accountsChanged', accountsChanged);
    };

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [account]);

  return {
    connect,
    signMessage,
    disconnect,
  };
}
