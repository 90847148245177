import request from '@/common/request/vault';

export const getClaimData = async (payload: { address: string; farmAddr: string }) => {
  return request.post<{
    max_balance: any;
    max_reward: any;
    deadline: any;
    signature: any;
  }>('/main/api/v1/farm/genClaimAll', payload);
};

export const getClaimHistory = async (payload: { address: string; farmAddr: string }) => {
  return request.post<
    {
      amount: string;
      period: any;
      apy: any;
      start_time: any;
      end_time: any;
      rewards: any;
    }[]
  >('/main/api/v1/farm/history', payload);
};

export const getStakeInfo = async (payload: { address: string; farmAddr: string }) => {
  return request.post<{
    staked: string;
    earned: string;
    apy: string;
    period: string;
    claimable_balance: string;
    claimable_reward: string;
    conf: {
      level: number;
      range: number[];
      apy: number;
    }[];
  }>('/main/api/v1/farm/dashboard', payload);
};
