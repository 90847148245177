import { Account, WalletKey } from '@/common/types/App';
import { ConfigProvider, Modal, message } from 'antd';
import { useWallet } from '@/common/hooks/bitcoinWallet/useWallet';
import { useWalletStore } from '@/stores/useBitcoinWalletStore';

const wallets = [
  {
    name: 'OKX Wallet',
    key: 'okxwallet',
  },
  {
    name: 'UniSat Wallet',
    key: 'unisat',
  },
];

const iconMap: Record<string, string> = {
  unisat: '/static/images/unisat.jpeg',
  okxwallet: '/static/images/okx.png',
};

export function Profile({ onSuccess }: { onSuccess?: (account: Account) => void }) {
  const { setCurrentWallet } = useWalletStore();


  const { getWallet } = useWallet({
    onConnect(account) {
      onSuccess?.(account);
    },
  });

  const handleConnect = async ({ key }: { key: WalletKey }) => {
    if (!window[key]) {
      message.info({
        content: 'Please install the wallet extension first',
        key: 'wallet',
      });

      return;
    }
    setCurrentWallet(key);
    getWallet(key).connect();
  };

  return (
    <div className="flex justify-center items-center flex-col  gap-y-2 text-lg cursor-pointer gap-x-[2%]">
      {wallets.map((item) => {
        return (
          <div
            onClick={() => {
              handleConnect({ key: item.key as WalletKey });
            }}
            className="w-full flex justify-start items-center gap-x-3 text-white text-opacity-70 border border-solid border-primary  px-[15px] py-[15px] rounded-md "
            key={item.name}
          >
            <img className="w-[22px] rounded-sm" src={iconMap[item.key]} />
            {item.name}
          </div>
        );
      })}
    </div>
  );
}

export function WalletModal({
  open,
  onClose,
  onConnected,
}: {
  open: boolean;
  onChoose?: (key: WalletKey) => void;
  onClose?: () => void;
  onConnected?: (account: Account) => void;
}) {
  return (
    <ConfigProvider
      theme={{
        token: {},
      }}
    >
      <Modal open={open} onCancel={onClose} footer={false} width={380} title="     Connect wallet">
        <div className="bg-componentBackground rounded-[35px] pt-[15px]">
          <Profile onSuccess={onConnected} />
        </div>
      </Modal>
    </ConfigProvider>
  );
}
